<template>
  <van-field
      v-bind="props.option.option"
      :placeholder="props.option.placeholder"
      readonly
      :required="props.required"
      :name="props.option.field"
  >
    <template #input>
      <van-radio-group class="w-100" v-model="selectValue">
        <div class="tal-w-[100%]">
          <div class="zong-bu-ju" v-for="(vo,index) in columns" :key="index">
            <div>
              <van-radio :name="vo.value">
              </van-radio>
            </div>
            <div class="text-input-bu-ju">
              <span>
                {{ vo.text }}
              </span>
              <div class="input-div" v-if="vo.children &&  selectValue === vo.value">
                <div :id="`input-${v2.field}`" class="input-table" v-for="(v2,i2) in vo.children" :key="i2">
                  <span>{{ v2.label }}</span>
                  <input
                      :type="v2.type"
                      :placeholder="`请输入${v2.label}`"
                      v-model="children_val[v2.field]"
                      class="input"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </van-radio-group>
    </template>
  </van-field>
</template>

<script setup>
//单选框
import {computed, defineEmit, defineProps, inject, onMounted, ref, watch} from "vue";
import XEUtils from "xe-utils";


const emits = defineEmit(['update:modelValue', 'children'])
const props = defineProps({
  required: Boolean,
  option: Object,
  modelValue: [Number, String],
  formValue: Object
})
const selectValue = ref('') //选中的内容
const children_val = ref({}) //子内容输入


const formValue = inject('formValue')

onMounted(() => {
  //获取所有字段
  XEUtils.each(columns.value, n => {
    XEUtils.each(n.children, n2 => {
      if (XEUtils.isUndefined(children_val.value[n2.field])) {
        children_val.value[n2.field] = ''
      }
    })
  })

  watch(() => props.formValue, () => {
    //对比内容
    XEUtils.objectEach(children_val.value, (v, k) => {
      const val = XEUtils.isUndefined(props.formValue[k]) ? '' : props.formValue[k] //获取form的数据
      if (val !== v) {
        children_val.value[k] = val
      }
    })
  }, {
    deep: true,
    immediate: true
  })

  watch(() => children_val.value, () => {
    XEUtils.objectEach(children_val.value, (value, key) => {
      emits('children', {value, key})
    })
  }, {
    deep: true
  })
})

//同步上级
watch(() => selectValue.value, () => {
  emits('update:modelValue', selectValue.value)
})
//同步本组件
watch(() => props.modelValue, n => {
  if (props.modelValue !== selectValue.value) {
    selectValue.value = props.modelValue
  }
}, {
  immediate: true
})


//false = 禁用
const isJinYong = computed(() => {
  return !props.option.option.disabled && !props.option.option.readonly
})

/**
 * 检测是不是异步获取 true=异步
 * @returns {boolean}
 */
function isYiBu() {
  return !XEUtils.isUndefined(XEUtils.get(props.option, 'method'))
}

const columns = computed(() => {
  return XEUtils.get(props.option, 'dataList', [])
})
</script>

<style scoped lang="less">
.w-100 {
  width: 100%;
}

.zong-bu-ju {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.text-input-bu-ju {
  padding-left: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex: 1;

  .input-div {
    flex: 1;
  }

  .input-table {
    padding-left: 10px;
    display: flex;
    flex-direction: column;
    align-content: flex-start;

    .input {
      background-color: #1469bd00;
      border: #aca9a97d solid 1px;
    }
  }

}
</style>