<script setup>
import { computed, onMounted, ref, watch } from 'vue';
import LunBo from '@/views/shouJiInfo2/components/LunBo.vue';
import FooterButton from '@/views/shouJiInfo2/components/footerButton.vue';
import { formValidate } from './components/formConfig.js';
import { pageNameList } from './components/formFIeld.js';
import LolForm from '@/components/form/LolForm.vue';
import XEUtils from 'xe-utils';
import { Dialog, Toast } from 'vant';
import { sdstorage } from '@/api/sdstorage';
import { useRoute } from 'vue-router';
import http from '@/api/http';
import ShowInfo from '@/views/shouJiInfo2/components/ShowInfo.vue';
import to from 'await-to-js';
import DirTip from './components/DirTip.vue';

const route = useRoute();
const pageNum = ref(1);
const formData = ref({}); //本次添加的数据
const ollData = ref({}); //已保存的数据
const tui_jian_ren = XEUtils.get(route.query, 'tui_jian_ren');
const refDirTip = ref(null)
if (!XEUtils.isEmpty(sdstorage.get('shouJiInfo2'))) {
  formData.value = sdstorage.get('shouJiInfo2');
}

//防抖
const jie_liu = XEUtils.debounce(() => {
  sdstorage.save('shouJiInfo2', XEUtils.toStringJSON(XEUtils.toStringJSON(formData.value)), '1y');
}, 2000);
onMounted(() => {
  jie_ce_save();
  if (!XEUtils.isEmpty(tui_jian_ren)) {
    formData.value.tui_jian_ren = tui_jian_ren;
  }
  watch(() => formData.value, () => {
    jie_liu();
  }, {
    deep: true,
  });
});

//true=已经填写
const is_over = computed(() => {
  return !XEUtils.isEmpty(ollData.value);
});

function jie_ce_save() {
  //检测是否已经保存了
  http.get('/gzhphp/shou_ji_info_two/get').then(({ code, data }) => {
    if (code !== 1) {
      refDirTip.value.show()
      return;
    }
    if (!XEUtils.isEmpty(data.data)) {
      ollData.value = data.data;
    }
  });
}

async function validate(fun) {
  for (let i = 0; i < pageNameList.length; i++) {
    const sch = formValidate(pageNameList[i].role.properties);
    const [e] = await to(new Promise((resolve, reject) => {
      sch.validate(formData.value, { first: true, form: formData.value }, (e, da) => {
        if (e) {
          reject(e);
        } else {
          resolve(da);
        }
      });
    }));
    if (e) {
      pageNum.value = i+1
      Dialog.alert({
        title: '提示',
        message: `第${ i + 1}页：`+XEUtils.get(e, '0.message'),

      }).then(()=>{
        document.getElementById('input-' + XEUtils.get(e, '0.field'))?.scrollIntoView({ behavior: 'smooth' });
      });
      return;
    }
    if (i === pageNameList.length - 1) {
      fun();
    }

  }

}

//上一页
function changePage(type) {
  const url = XEUtils.isNumber(formData.value.id) ? '/gzhphp/shou_ji_info_two/edit' : '/gzhphp/shou_ji_info_two/add';
  switch (type) {
    case 'next':
      pageNum.value++;

      break;
    case 'up':
      pageNum.value--;
      break;
    case 'submit':
      validate(async () => {
        const [e, { code, msg }] = await to(http.post(url, formData.value, {
          params: {
            id: XEUtils.get(formData.value, 'id'),
          },
        }));
        if (code === 1) {
          Toast.success(msg);
          jie_ce_save();
        }
      });

  }
}

function reset() {
  ollData.value = {};
  pageNum.value = 1;
}

</script>

<template>
  <div
      v-if="!is_over"
      class="tal-max-w-screen-sm tal-m-auto tal-h-screen tal-bg-gray-100 tal-flex tal-flex-col"
  >
    <div class="tal-flex-1 tal-overflow-hidden tal-overflow-y-auto">
       <lun-bo/>
       <div class="tal-bg-white tal-pb-5 tal-pt-3">
         <div class="tal-flex tal-items-center  tal-justify-between tal-text-2xl">
           <div class="tal-pl-4">
             <div class="tal-h-5 tal-w-2 tal-bg-zhu-se"></div>
           </div>
           <div class="tal-flex-1 tal-pl-2 ">
             {{ pageNameList[pageNum - 1].name }}
           </div>
           <div class="tal-pr-4">
             {{ pageNum }} / {{ pageNameList.length }}
           </div>
         </div>
       </div>
       <lol-form
           v-model="formData"
           v-bind="pageNameList[pageNum-1].role"
       />
    </div>
    <div class="">
      <footer-button v-model="pageNum" @change="changePage" :sum-number="pageNameList.length"/>
    </div>
  </div>
  <show-info @reset="reset" :info="ollData" v-else/>
  <dir-tip ref="refDirTip" />

</template>

<style scoped lang="less">

</style>