import { areaList } from '@/assets/js/zhe_jiang_vant-area-data';
import XEUtils from 'xe-utils'
import moment from 'moment'
const self_year = moment('2024-11-13 00:00:00')
function getTableList(set_year, field_config) {
  let list = [];
  XEUtils.each(field_config, n2 => {
    // const n3 = XEUtils.toStringJSON(XEUtils.toJSONString(n2))
    XEUtils.each(set_year, n => {
      const year = self_year.format('YYYY') - n;
      // n2.field = `${year}_${n2.field}`
      // n2.label = `${year}年${n2.label}`
      list.push({
        ...n2,
        field: `${year}_${n2.field}`,
        label: `${year}年${n2.label}`,
      });
    });
    list.push({
      type: 'min-xian',
    });
  });
  return list;
}


const chan_ye_lian_guan_jian_ling_yu_shi_xian_bu_duan_ban = {
  //值 = xx 其中哪些不能为空
  field: 'chan_ye_lian_guan_jian_ling_yu_shi_xian_bu_duan_ban',//那个字段
  value: '是',
  message: '至少填写一个：“补短板”的产品名称、填补国内（国际）空白的领域、替代进口的国外企业（或产品）名称 ',
  set_validate_fiend: [ //
    'chan_ye_lian_guan_jian_ling_yu_shi_xian_bu_duan_ban_name',
    'chan_ye_lian_guan_jian_ling_yu_shi_xian_bu_duan_ban_tian_bu',
    'chan_ye_lian_guan_jian_ling_yu_shi_xian_bu_duan_ban_dai_ti',
  ],
  required_num: 1,//几个不能空
};

//重要指标模板
const zhong_yao_zhu_biao_mo_ban = [
  {
    type: 'number', label: '全职员工数量（人）',
    field: 'quan_zhi_yuan_gong_shu_liang',
    required: true,
    group: true,
  },
  {
    type: 'number',
    label: '其中：研发人员数量（人）',
    field: 'yan_fa_ren_yuan_shu_liang',
    required: true,
    group: true,
  },
  {
    type: 'number',
    label: '营业收入(万元)',
    field: 'ying_ye_shou_ru',
    required: true,
    group: true,
  },
  {
    type: 'number',
    label: '其中：主营业务收入(万元)',
    field: 'zhu_ying_ye_wu_shou_ru',
    required: true,
    group: true,
  },
  {
    type: 'number',
    label: '主营业务收入增长率(%)',
    field: 'zhu_ying_ye_wu_shou_ru_zeng_zhang_lv',
    required: true,
    group: true,
  },
  {
    type: 'number',
    label: '净利润总额(万元)',
    field: 'jing_li_run_zong_e',
    required: true,
    group: true,
  }, {
    type: 'number',
    label: '净利润增长率(%)',
    field: 'jing_li_run_zeng_zhang_lv',
    required: true,
    group: true,
  }, {
    type: 'number',
    label: '资产总额(万元)',
    field: 'zi_chan_zong_e',
    required: true,
    group: true,
  }, {
    type: 'number',
    label: '资产负债率（%）',
    field: 'zi_chan_fu_zhai_lv',
    required: true,
    group: true,
  }, {
    type: 'number',
    label: '上缴税金(万元)',
    field: 'shang_jiao_shui_jin',
    required: true,
    group: true,
  }, {
    type: 'number',
    label: '股权融资总额(万元)',
    field: 'gu_quan_rong_zi_zong_e',
    required: true,
    group: true,
  }, {
    type: 'number',
    label: '对应估值(万元)',
    field: 'dui_ying_gu_zhi',
    required: true,
    group: true,
  }, {
    type: 'number',
    label: '银行贷款(万元)',
    field: 'yin_hang_dai_kuan',
    required: true,
    group: true,
  }, {
    type: 'number',
    label: '境内债券(万元)',
    field: 'jing_nei_zhai_quan',
    required: true,
    group: true,
  }, {
    type: 'number',
    label: '境外债券(万元)',
    field: 'jing_wai_zhai_quan',
    required: true,
    group: true,
  },
];
let zhong_yao_zhu_biao_mo_ban_all = getTableList([3, 2, 1],
  zhong_yao_zhu_biao_mo_ban);

//研发费用总额模版
const yan_fa_fei_sum = [
  {
    type: 'number',
    label: '研发费用总额(万元)',
    field: 'yan_fa_fei_yong_zong_e',
    required: true,
    group: true,
  }, {
    type: 'number',
    label: '研发费用总额占营业收入总额比重(%)',
    field: 'yan fa fei yong zong e zhan ying ye shou ru',
    required: true,
    group: true,
  }, {
    type: 'number',
    label: '研发人员占全部职工比重(%)',
    field: 'yan_fa_ren_yuan_zhan_quan_bu_zhi_gong_bi_zhong',
    required: true,
    group: true,
  },
];
const yan_fa_fei_sum_all = getTableList([3, 2, 1], yan_fa_fei_sum);

//特色化发展情魔板
const teSeHuaFaZhanQingMoBan = [
  {
    type: 'number',
    label: '主导产品国际细分市场占有率(%)',
    field: 'leading_products_international_segment_market_share',
    required: true,
    group: true,
  }, {
    type: 'number',
    label: '主导产品全国细分市场占有率(%)',
    field: 'zhu_dao_chan_pin_guo_ji_xi_fen_shi_chang_zhan_you_lv',
    required: true,
    group: true,
  }, {
    type: 'number',
    label: '主导产品出口额(万元)',
    field: 'zhu_dao_chan_pin_chu_kou_e',
    required: true,
    group: true,
  }, {
    type: 'number',
    label: '企业自有品牌个数（个）',
    field: 'qi_ye_zi_you_pin_pai_ge_shu',
    required: true,
    group: true,
  }, {
    type: 'number',
    label: '企业自有品牌销售收入(万元)',
    field: 'qi_ye_zi_you_pin_pai_xiao_shou_shou_ru',
    required: true,
  },
];

/**
 * 拼接表格列表
 * @returns {*[]}
 */

const teSeHuaFaZhanQingMoBanAll = getTableList([2, 1], teSeHuaFaZhanQingMoBan);

//选择配置
const select_radio = [
  {
    text: '有', value: '有',
  }, {
    text: '无', value: '无',
  },
];

const select_radio_is = [
  {
    text: '是', value: '是',
  },
  {
    text: '否', value: '否',
  },
];

export const pageNameList = [
  {
    name: '一、企业基本情况', role: {
      isShowButton: false, properties: [
        {
          type: 'text',
          label: '企业名称',
          field: 'corporation_name',
          required: true,
        }, {
          type: 'selectArea',
          label: '企业注册地',
          areaList,
          field: 'corporation_address',
          required: true,
        }, {
          type: 'text',
          label: '通讯地址',
          field: 'mailing_address',
          required: true,
        }, {
          type: 'number', label: '邮编', field: 'postcode', required: true,
        }, {
          type: 'xian', label: '法定代表人信息',
        },
        {
          type: 'text',
          label: '法定代表人',
          field: 'legal_person_name',
          required: true,
        }, {
          type: 'text',
          label: '电话',
          field: 'legal_person_tel',
          required: true,

        }, {
          type: 'text', label: '手机',

          validate: {
            type: 'string',
            pattern: /^\d{11}$/,
            required: true,
            message: '请输入11位数字的手机号',
          },
          field: 'legal_person_phone',
          required: true,
        }, {
          type: 'xian', label: '控股信息',
        }, {
          type: 'text',
          label: '控股股东',
          field: 'kong_gu_gu_dong',
          required: true,
        }, {
          type: 'text',
          label: '实际控制人',
          field: 'shi_ji_kong_zhi_ren',
          required: true,
        }, {
          type: 'text',
          label: '实际控制人国籍',
          field: 'shi_ji_kong_zhi_ren_guo_ji',
          required: true,
        }, {
          type: 'text',
          label: '联系人',
          field: 'contact_person',
          required: true,
        }, {
          type: 'text',
          label: '电话',
          field: 'contact_person_tel',
          required: true,
        }, {
          type: 'text', label: '手机',
          validate: {
            type: 'string',
            pattern: /^\d{11}$/,
            required: true,
            message: '请输入11位数字的手机号',
          },
          field: 'contact_person_phone', required: true,
        }, {
          type: 'text',
          label: '传真',
          field: 'contact_person_fax',
        }, {
          type: 'text',
          label: 'E-mail',
          field: 'contact_person_email',
          validate: {
            type: 'email',
            message: '请正确输入E-mail',
          },
        }, {
          type: 'xian', label: '公司信息',
        }, {
          type: 'ymd',
          label: '注册时间',
          field: 'zhu_ce_shi_jian',
          required: true,
        }, {
          type: 'number',
          label: '注册资本（万元）',
          field: 'zhu_ce_zi_ben',
          required: true,
        }, {
          type: 'text',
          label: '统一社会信用代码（18位）',
          field: 'tong_yi_she_hui_xin_yong_dai_ma',
          validate: {
            type: 'string',
            pattern: /^(\d|\w|\W){18}$/,
            required: true,
            message: '请正确输入统一社会信用代码',
          },
          required: true,
        }, {
          type: 'radio',
          label: '根据《中小企业划型标准规定》（工信部联企业2011〕300 号），企业规模属于',
          field: 'zhong_xiao_qi_ye_hua_xing_biao_zhun_gui_ding',
          required: true,
          dataList: [
            {
              value: '大型', text: '大型',
            }, {
              value: '中型', text: '中型',
            }, {
              value: '小型', text: '小型',
            }, {
              value: '微型', text: '微型',
            },
          ],
        }, {
          type: 'text',
          label: '所属行业（2位数代码及名称）',
          field: 'suo_shu_hang_ye_dai_ma_ji_ming_cheng',
          required: true,
          // validate: {
          //     len: 2,
          //     required: true,
          //     message: '请正确输入所属行业'
          // }
        }, {
          type: 'text',
          label: '具体细分领域（4位数代码及名称）',
          field: 'ju_ti_xi_fen_ling_yu',
          required: true,
          // validate: {
          //     len: 4,
          //     required: true,
          //     message: '请正确输入具体细分领域'
          // }
        },
        {
          type: 'radio',
          label: '企业类型',
          field: 'qi_ye_lei_xing',
          required: true,
          dataList: [
            {
              value: '国有', text: '国有',
            }, {
              value: '合资', text: '合资',
            }, {
              value: '民营', text: '民营',
            }, {
              value: '外资', text: '外资',
            },
          ],
        }, {
          type: 'xian',
          label: '企业存在控股关系',
          field: 'qi_ye_cun_zai_kong_gu_guan_xi',

        },
        {
          type: 'radioChildren',
          label: '是否与现有“小巨人”企业存在控股',
          field: 'qi_ye_cun_zai_kong_gu',
          required: true,
          dataList: [
            {
              value: '否',
              text: '否',
            },
            {
              value: '是',
              text: '是',
              children: [
                {
                  type: 'text',
                  label: '存在控股关系企业名称',
                  field: 'cun_zai_kong_gu_guan_xi_qi_ye_ming_cheng',
                  required_where: {
                    field: 'qi_ye_cun_zai_kong_gu',
                    value: '是',
                  },
                },
              ],
            },
          ],
        },

        {
          type: 'radioChildren',
          label: '同集团内是否有其他生产相似主导产品企业获得认定或申报',
          field: 'tong_ji_tuan_nei_shi_fou_you_qi_ta_sheng_chan',
          required: true,
          dataList: [
            {
              value: '否',
              text: '否',
            },
            {
              value: '是',
              text: '是',
              children: [
                {
                  type: 'text',
                  label: '获认定/申报企业名称',
                  field: 'shen_bao_qi_ye_ming_cheng',
                  required_where: {
                    field: 'tong_ji_tuan_nei_shi_fou_you_qi_ta_sheng_chan',
                    value: '是',
                  },
                },
              ],
            },

          ],
        },
        {
          type: 'radioChildren',
          label: '上市情况',
          field: 'shang_shi_qing_kuang',
          required: true,
          dataList: [
            {
              value: '无上市计划', text: '无上市计划',
            }, {
              value: '有上市计', text: '有上市计',
            },
            {
              value: '已上市', text: '已上市',
              children: [
                {
                  type: 'text',
                  label: '股票代码',
                  field: 'gu_piao_dai_ma',
                  required_where: {
                    field: 'shang_shi_qing_kuang',
                    value: '已上市',
                  },
                },
              ],
            },
          ],
        },

        {
          type: 'xian',
          label: '上市计划（如有，请填写）',
        },
        {
          type: 'radio',
          label: '1.上市进程',
          field: 'shang_shi_jin_cheng',
          dataList: [
            {
              value: '未进行上市前股改', text: '未进行上市前股改',
            },
            {
              value: '已完成上市前股改', text: '已完成上市前股改',
            },
            {
              value: '已提交上市申请', text: '已提交上市申请',
            },
          ],
          group: true,
          required_where: {
            field: 'shang_shi_qing_kuang',
            value: '有上市计',
          },
        },
        {
          type: 'radioChildren',
          label: '2.拟上市地',
          field: 'ni_shang_shi_di',
          dataList: [
            {
              value: '上交所 主 板', text: '上交所 主 板',
            }, {
              value: '上交所 科创板', text: '上交所 科创板',
            }, {
              value: '深交所 主 板', text: '深交所 主 板',
            }, {
              value: '深交所 创业板', text: '深交所 创业板',
            }, {
              value: '北交所', text: '北交所',
            }, {
              value: '境外', text: '境外',
              children: [
                {
                  type: 'text',
                  label: '境外',
                  field: 'jing_wai',
                  required_where: {
                    field: 'ni_shang_shi_di',
                    value: '境外',
                  },
                },
              ],
            },
          ],
          required_where: {
            field: 'shang_shi_qing_kuang',
            value: '有上市计',
          },
        },
      ],
    },
  },
  {
    name: '二、经济效益和经营情况',
    role: {
      isShowButton: false,
      properties: [
        ...zhong_yao_zhu_biao_mo_ban_all,
        {
          type: 'radioChildren',
          label: '近 3 年是否申请银行贷款',
          field: 'nian_shi_fou_shen_qing_yin_hang_dai_kuan',
          required: true,
          group: true,
          dataList: [
            {
              text: '否', value: '否',
            },
            {
              text: '是', value: '是',
              children: [
                {
                  type: 'number',
                  label: '请填写：信贷满足率 %（企业获批贷款额度/贷款申请额度）',
                  field: 'xin_dai_man_zu_lv',
                  required_where: {
                    field: 'nian_shi_fou_shen_qing_yin_hang_dai_kuan',
                    value: '是',
                  },
                },
              ],
            },
          ],
        },
        {
          type: 'checkbox',
          label: '所获得贷款主要用于下面哪些事项(可多选)',
          field: 'suo_huo_de_dai_kuan_zhu_yao_yong_yu_xia_mian_nei_xie_shi_xiang',
          dataList: [
            {
              text: '日常生产经营', value: '日常生产经营',
            },
            {
              text: '扩大生产', value: '扩大生产',
            },
            {
              text: '研发及技术改造', value: '研发及技术改造',
            },
            {
              text: '海外分支机构运营及投资并购',
              value: '海外分支机构运营及投资并购',
            },
          ],
          required_where: {
            field: 'nian_shi_fou_shen_qing_yin_hang_dai_kuan', value: '是',
          },
        }, {
          type: 'xian', label: '下一步融资计划',
        },
        {

          type: 'text',
          label: '资金需求额(万元)',
          field: 'zi_jin_xu_qiu_e',
          required: true,
          group: true,
        }, {
          type: 'checkbox',
          label: '计划融资方式',
          field: 'ji_hua_rong_zi_fang_shi',
          required: true,
          dataList: [
            {
              text: '银行贷款', value: '银行贷款',
            }, {
              text: '股权融资', value: '股权融资',
            }, {
              text: '债券融资', value: '债券融资',
            }, {
              text: '上市融资', value: '上市融资',
            }, {
              text: '其他', value: '其他',
            },
          ],
        },
      ],
    },
  },
  {
    name: '三、专业化发展水平', role: {
      isShowButton: false, properties: [
        {
          type: 'number',
          label: '企业从事特定细分市场时间(年)',
          field: 'qi_ye_cong_shi_te_ding_xi_fen_shi_chang_shi_jian',
          required: true,
        }, {
          type: 'number',
          label: '主营业务收入占营业收入比重(%)',
          field: 'zhu_ying_ye_wu_shou_ru_zhan_ying_ye_shou_ru_bi_zhong',
          required: true,
        }, {
          type: 'number',
          label: '近 2 年主营业务收入平均增长率(%)',
          field: 'nian_zhu_ying_ye_wu_shou_ru_ping_jun_zeng_zhang_lv',
          required: true,
        },
        {
          type: 'xian',
          label: '四、精细化发展水平',
        },
        {
          type: 'checkbox',
          label: '企业获得的管理体系认证情况（可多选）（没有请在其他说明内写“无”）',
          field: 'qi_ye_huo_de_de_guan_li_ti_xi_ren_zheng_qing_kuang',
          required: true,
          group: true,
          dataList: [
            {
              text: 'ISO9000 质量管理体系认证',
              value: 'ISO9000 质量管理体系认证',
            }, {
              text: 'ISO14000 环境管理体系认证',
              value: 'ISO14000 环境管理体系认证',
            }, {
              text: 'OHSAS18000 职业安全健康管理体系认证',
              value: 'OHSAS18000 职业安全健康管理体系认证',
            }, {
              text: '其他', value: '其他',
            },
          ],
        },
        {
          type: 'textarea',
          label: '其他请说明',
          field: 'qi_ye_huo_de_de_guan_li_ti_xi_ren_zheng_qing_kuang_qi_ta',
          required_where: {
            field: 'qi_ye_huo_de_de_guan_li_ti_xi_ren_zheng_qing_kuang',
            value: '其他',
          },
        }, {
          type: 'checkbox',
          label: '产品获得发达国家或地区权威机构认证情况（可多选）（没有请在其他说明内写“无”）',
          field: 'chan_pin_huo_de_fa_da_guo_jia_huo_di_qu_quan_wei_ji_gou_ren_zheng_qing_kuang',
          required: true,
          group: true,
          dataList: [
            {
              text: 'UL', value: 'UL',
            }, {
              text: 'CSA', value: 'CSA',
            }, {
              text: 'ETL', value: 'ETL',
            }, {
              text: 'GS', value: 'GS',
            }, {
              text: '其他', value: '其他',
            },
          ],
        },
        {
          type: 'textarea',
          label: '其他请说明(产品获得发达国家或地区权威机构认证情况)',
          field: 'chan_pin_huo_de_fa_da_guo_jia_huo_di_qu_quan_wei_ji_gou_ren_zheng_qing_kuang_qi_ta',
          required_where: {
            field: 'chan_pin_huo_de_fa_da_guo_jia_huo_di_qu_quan_wei_ji_gou_ren_zheng_qing_kuang',
            value: '其他',
          },
        }, {
          type: 'checkbox',
          label: '核心业务采用信息系统支撑情况（可多选）（没有请在其他说明内写“无”）',
          field: 'he_xin_ye_wu_cai_yong_xin_xi_xi_tong_zhi_cheng_qing_kuang',
          required: true,
          group: true,
          dataList: [
            {
              text: '研发设计 CAX', value: '研发设计 CAX',
            }, {
              text: '生产制造 CAM', value: '生产制造 CAM',
            }, {
              text: '经营管理 ERP/OA', value: '经营管理 ERP/OA',
            }, {
              text: '运维服务 CRM', value: '运维服务 CRM',
            }, {
              text: '供应链管理 SRM', value: '供应链管理 SRM',
            }, {
              text: '其他', value: '其他',
            },
          ],
        }, {
          type: 'textarea',
          label: '其他请说明(核心业务采用信息系统支撑情况)',
          field: 'he_xin_ye_wu_cai_yong_xin_xi_xi_tong_zhi_cheng_qing_kuang_qi_ta',
          required_where: {
            field: 'he_xin_ye_wu_cai_yong_xin_xi_xi_tong_zhi_cheng_qing_kuang',
            value: '其他',
          },
        }, {
          type: 'xian', label: '数字化赋能',
        }, {
          type: 'radio',
          label: '业务系统是否向云端迁移',
          field: 'ye_wu_xi_tong_shi_fou_xiang_yun_duan_qian_yi',
          required: true,
          dataList: [
            {
              text: '是', value: '是',
            }, {
              text: '否', value: '否',
            },
          ],
        }, {
          type: 'radio',
          label: '是否拥有制造业与互联网融合试点示范或智能制造试点示范项目',
          field: 'fan_huo_zhi_neng_zhi_zao_shi_dian_shi_fan_xiang_mu',
          required: true,
          dataList: [
            {
              text: '是', value: '是',
            }, {
              text: '否', value: '否',
            },
          ],
        }, {
          type: 'radio',
          label: '是否智能制造试点示范企业',
          field: 'zhi_neng_zhi_zao_shi_dian',
          required: true,
          dataList: [
            {
              text: '是', value: '是',
            }, {
              text: '否', value: '否',
            },
          ],
        }, {
          type: 'radioChildren',
          label: '获得绿色工厂等认定情况',
          field: 'huo_de_lv_se_gong_chang_deng_ren_ding_qing_kuang',
          required: true,
          dataList: [
            {
              text: '国家级', value: '国家级',
            }, {
              text: '省级', value: '省级',
            }, {
              text: '市级', value: '市级',
            }, {
              text: '县（市、区）级', value: '县（市、区）级',
            }, {
              text: '无', value: '无',
            }, {
              text: '其他', value: '其他',
              children: [
                {
                  type: 'text',
                  label: '请说明名称、注明级别',
                  field: 'huo_de_lv_se_gong_chang_deng_ren_ding_qing_kuang_qi_ta',
                  required_where: {
                    field: 'huo_de_lv_se_gong_chang_deng_ren_ding_qing_kuang',
                    value: '其他',
                  },
                },
              ],
            },
          ],
        }, {
          type: 'radio',
          label: '获得质量标杆认定情况',
          field: 'huo_de_zhi_liang_biao_gan_ren_ding_qing_kuang',
          required: true,
          dataList: [
            {
              text: '国家级', value: '国家级',
            }, {
              text: '省级', value: '省级',
            }, {
              text: '市级', value: '市级',
            }, {
              text: '县（市、区）级', value: '县（市、区）级',
            }, {
              text: '无', value: '无',
            },
          ],
        }, {
          type: 'number',
          label: '企业资产负债率(%)',
          field: 'qi_ye_zi_chan_fu_zhai_lv',
          required: true,
        },
      ],
    },
  },

  {
    name: '五、特色化发展情况', role: {
      isShowButton: false, properties: [
        ...teSeHuaFaZhanQingMoBanAll,
        {
          type: 'xian', label: '境外经营情况',
        }, {

          type: 'radioChildren',
          label: '境外并购情况',
          field: 'jing_wai_bing_gou_qing_kuang', required: true,
          group: true,

          dataList: [
            {
              text: '无', value: '无',

            },
            {
              text: '有 如有，请填写：',
              value: '有',
              children: [
                {
                  type: 'number',
                  label: '总金额(境外并购情况)（万元）',
                  field: 'jing_wai_bing_gou_qing_kuang_money',
                  group: true,
                  required_where: {
                    field: 'jing_wai_bing_gou_qing_kuang', value: '有',
                  },
                },
                {
                  type: 'text',
                  label: '具体情况(境外并购情况)',
                  field: 'jing_wai_bing_gou_qing_kuang_shuo_ming',
                  required_where: {
                    field: 'jing_wai_bing_gou_qing_kuang',
                    value: '有',
                  },
                },
              ],
            },
          ],
        },

        {
          type: 'radioChildren',
          label: '境外设立分公司情况（境外设立销售公司或投资建有工厂）',
          field: 'jing_wai_she_li_fen_gong_si_qing_kuang',
          required: true,
          group: true,
          dataList: [
            {
              text: '无',
              value: '无',
            }, {
              text: '有 如有，请填写：',
              value: '有',
              children: [
                {
                  type: 'number',
                  label: '出资总额（境外设立分公司情况）(万元)',
                  field: 'jing_wai_she_li_fen_gong_si_qing_kuang_money',
                  group: true,
                  required_where: {
                    field: 'jing_wai_she_li_fen_gong_si_qing_kuang',
                    value: '有',
                  },
                }, {
                  type: 'textarea',
                  label: '具体情况（境外设立分公司情况）',
                  field: 'jing_wai_she_li_fen_gong_si_qing_kuang_shuo_ming',
                  required_where: {
                    field: 'jing_wai_she_li_fen_gong_si_qing_kuang',
                    value: '有',
                  },
                },
              ],
            },
          ],
        },

        {
          type: 'radioChildren',
          label: '境外设立研发机构情况',
          field: 'jing_wai_she_li_yan_fa_ji_gou_qing_kuang',
          required: true,
          group: true,
          dataList: [
            {
              text: '无',
              value: '无',
            },
            {
              text: '有 如有，请填写：',
              value: '有',
              children: [
                {
                  type: 'number',
                  label: '出资总额(境外设立研发机构情况)（万元）',
                  field: 'jing_wai_she_li_yan_fa_ji_gou_qing_kuang_money',
                  group: true,
                  required_where: {
                    field: 'jing_wai_she_li_yan_fa_ji_gou_qing_kuang',
                    value: '有',
                  },
                },
                {
                  type: 'text',
                  label: '具体情况(境外设立研发机构情况)',
                  field: 'jing_wai_she_li_yan_fa_ji_gou_qing_kuang_shuo_ming',
                  required_where: {
                    field: 'jing_wai_she_li_yan_fa_ji_gou_qing_kuang',
                    value: '有',
                  },
                },
              ],
            },
          ],
        },

        {
          type: 'radioChildren',
          label: '向境外支付专利使用费',
          field: 'xiang_jing_wai_zhi_fu_zhuan_li_shi_yong_fei',
          required: true,
          dataList: [
            {
              text: '无',
              value: '无',
            }, {
              text: '有 如有，请填写：',
              value: '有',
              children: [
                {
                  type: 'number',
                  label: '总金额(向境外支付专利使用费)（万元）',
                  field: 'xiang_jing_wai_zhi_fu_zhuan_li_shi_yong_fei_money',
                  required_where: {
                    field: 'xiang_jing_wai_zhi_fu_zhuan_li_shi_yong_fei',
                    value: '有',
                  },
                },
                {
                  type: 'text',
                  label: '具体情况(向境外支付专利使用费)',
                  field: 'xiang_jing_wai_zhi_fu_zhuan_li_shi_yong_fei_shuo_ming',
                  required_where: {
                    field: 'xiang_jing_wai_zhi_fu_zhuan_li_shi_yong_fei',
                    value: '有',
                  },
                },
              ],
            },
          ],
        },

        {
          type: 'xian',
          label: '作为主要起草单位制修订的已批准发布标准数量（无写0）',
        }, {
          type: 'number',
          label: '主导几项国际标准',
          field: 'zhu_dao_ji_xiang_guo_ji_biao_zhun',
          required: true,
          group: true,
        }, {
          type: 'number',
          label: '主导几项国家标准',
          field: 'zhu_dao_ji_xiang_guo_jia_biao_zhun',
          required: true,
          group: true,
        }, {
          type: 'number',
          label: '主导几项行业标准',
          field: 'zhu_dao_ji_xiang_hang_ye_biao_zhun',
          required: true,
          group: true,
        }, {
          type: 'number',
          label: '主导几项浙江省团体标准',
          field: 'zhu_dao_ji_xiang_zhe_jiang_sheng_tuan_ti_biao_zhun',
          required: true,
          group: true,
        }, {
          type: 'number',
          label: '参与几项国际标准',
          field: 'can_yu_ji_xiang_guo_ji_biao_zhun',
          required: true,
          group: true,
        }, {
          type: 'number',
          label: '参与几项国家标准',
          field: 'can_yu_ji_xiang_guo_jia_biao_zhun',
          required: true,
          group: true,
        }, {
          type: 'number',
          label: '参与几项行业标准',
          field: 'can_yu_ji_xiang_hang_ye_biao_zhun',
          required: true,
          group: true,
        }, {
          type: 'number',
          label: '参与几项浙江省团体标准',
          field: 'can_yu_ji_xiang_zhe_jiang_sheng_tuan_ti_biao_zhun',
          required: true,
        }, {
          type: 'texts',
          label: '名称（请填写代表性标准，不超过5项）',
          field: 'bian_zhun_xiang_name',
          required: true,
          maxNumber: 5,
        },
      ],
    },
  },
  {
    name: '六、创新能力水平', role: {
      isShowButton: false,
      properties: [
        {
          type: 'xian',
          label: '研发机构建设情况(企业自建或与高等院校、科研机构联合建立)',
        },
        {
          type: 'checkboxChildren',
          label: '技术研究院',
          field: 'technical_research_institute',
          dataList: [
            {
              text: '国家级有几个',
              value: '国家级有几个',
              children: [
                {
                  type: 'number',
                  label: '国家级有几个',
                  field: 'ji_shu_yan_jiu_yuan_guo_jia_ji',
                  group: true,
                  required_where: {
                    field: 'technical_research_institute',
                    value: '国家级有几个',
                  },
                },
              ],
            },
            {
              text: '省级有几个',
              value: '省级有几个',
              children: [
                {
                  type: 'number',
                  label: '省级有几个',
                  group: true,
                  field: 'ji_shu_yan_jiu_yuan_guo_sheng_ji_you_ji_ge',
                  required_where: {
                    field: 'technical_research_institute',
                    value: '省级有几个',
                  },
                },
              ],
            },
            {
              text: '自建有几个',
              value: '自建有几个',
              children: [
                {
                  type: 'number',
                  label: '自建有几个',
                  field: 'ji_shu_yan_jiu_yuan_zi_jian_you_ji_ge',
                  required_where: {
                    field: 'technical_research_institute',
                    value: '自建有几个',
                  },
                },
              ],
            },
          ],
        },

        {
          type: 'checkboxChildren',
          label: '企业技术中心',
          field: 'qi_ye_ji_shu_zhong_xin_guo_jia_ji',
          dataList: [
            {
              text: '国际级有几个',
              value: '国际级有几个',
              children: [
                {
                  type: 'number',
                  label: '企业技术中心国际级有几个',
                  field: 'enterprise_technology_center_has_several_international_level',
                  required_where: {
                    field: 'qi_ye_ji_shu_zhong_xin_guo_jia_ji',
                    value: '国际级有几个',
                  },
                },
              ],
            },
            {
              text: '省级有几个',
              value: '省级有几个',
              children: [
                {
                  type: 'number',
                  label: '企业技术中心省级有几个',
                  field: 'qi_ye_ji_shu_zhong_xin_sheng_ji',
                  required_where: {
                    field: 'qi_ye_ji_shu_zhong_xin_guo_jia_ji',
                    value: '省级有几个',
                  },
                },
              ],
            },
            {
              text: '自建有几个',
              value: '自建有几个',
              children: [
                {
                  type: 'number',
                  label: '企业技术中心自建有几个',
                  field: 'qi_ye_ji_shu_zhong_xin_zi_jian',
                  required_where: {
                    field: 'qi_ye_ji_shu_zhong_xin_guo_jia_ji',
                    value: '自建有几个',
                  },
                },
              ],
            },
          ],
        },
        {
          type: 'checkboxChildren',
          label: '企业工程中心',
          field: 'enterprise_engineering_center',
          dataList: [
            {
              text: '国家级有几个',
              value: '国家级有几个',
              children: [
                {
                  type: 'number',
                  label: '国家级有几个',
                  field: 'qi_ye_gong_cheng_zhong_xin_guo_jia_ji',
                  required_where: {
                    field: 'enterprise_engineering_center',
                    value: '国家级有几个',
                  },
                },
              ],
            },
            {
              text: '省级有几个',
              value: '省级有几个',
              children: [
                {
                  type: 'number',
                  label: '省级有几个',
                  field: 'qi_ye_gong_cheng_zhong_xin_sheng_ji',
                  required_where: {
                    field: 'enterprise_engineering_center',
                    value: '省级有几个',
                  },
                },
              ],
            },
            {
              text: '自建有几个',
              value: '自建有几个',
              children: [
                {
                  type: 'number',
                  label: '自建有几个',
                  field: 'qi_ye_gong_cheng_zhong_xin_zi_jian',
                  required_where: {
                    field: 'enterprise_engineering_center',
                    value: '自建有几个',
                  },
                },
              ],
            },
          ],
        },
        {
          type: 'checkboxChildren',
          field: 'industrial_design_center',
          label: '工业设计中心',
          dataList: [
            {
              text: '国家级有几个',
              value: '国家级有几个',
              children: [
                {
                  type: 'number',
                  label: '国家级有几个',
                  field: 'gong_ye_she_ji_zhong_xin_guo_jia_ji',
                  required_where: {
                    field: 'industrial_design_center',
                    value: '国家级有几个',
                  },
                },
              ],
            },
            {
              text: '省级有几个',
              value: '省级有几个',
              children: [
                {
                  type: 'number',
                  label: '省级有几个',
                  field: 'gong_ye_she_ji_zhong_xin_guo_jia_ji_1',
                  required_where: {
                    field: 'industrial_design_center',
                    value: '省级有几个',
                  },
                },
              ],
            },
            {
              text: '自建有几个',
              value: '自建有几个',
              children: [
                {
                  type: 'number',
                  label: '自建有几个',
                  field: 'gong_ye_she_ji_zhong_xin_sheng_ji',
                  required_where: {
                    field: 'industrial_design_center',
                    value: '自建有几个',
                  },
                },
              ],
            },
          ],
        },

        {
          type: 'radio',
          label: '院士专家工作站',
          required: true,
          field: 'yuan_shi_zhuan_jia_gong_zuo_zhan',
          dataList: [
            {
              text: '有', value: '有',
            }, {
              text: '无', value: '无',
            },
          ],
        }, {
          type: 'radio',
          label: '博士后工作站',
          field: 'bo_shi_hou_gong_zuo_zhan',
          required: true,
          dataList: [
            {
              text: '有', value: '有',
            }, {
              text: '无', value: '无',
            },
          ],
        }, {
          type: 'texts',
          label: '合作院校机构名称（3个以内)（无合作院校机构写“无”）',
          field: 'he_zuo_yuan_xiao_ji_gou_ming_cheng',
          required: true,
          maxNumber: 5,
        }, {
          type: 'textarea',
          label: '研究领域已获得成果及应用情况（300字）',
          field: 'yan_jiu_ling_yu_yi_huo_de_cheng_guo',
          maxLength: 300,
          required: true,
        },

        {
          type: 'xian', label: '相关指标',
        },
        ...yan_fa_fei_sum_all,
        {
          type: 'xian',
          label: '拥有与主导产品有关的I类知识产权情况（无写0）',
        },
        {
          type: 'number',
          label: 'I类知识产权总数（项）',
          field: 'i_lei_zhi_shi_chan_quan_zong_shu',
          required: true,
          group: true,
        }, {
          type: 'number',
          label: 'II类知识产权总数（项）',
          field: 'ii_lei_zhi_shi_chan_quan_zong_shu',
          required: true, group: true,
        },
        {
          type: 'number',
          label: '其中发明专利总数（项）',
          field: 'qi_zhong_fa_ming_zhuan_li_zong_shu',
          required: true,
          group: true,
        },
        {
          type: 'number',
          label: '植物新品种总数（项）',
          field: 'zhi_wu_xin_pin_zhong_zong_shu',
          required: true,
          group: true,
        }, {
          type: 'number',
          label: '国家级农作物品种总数（项）',
          field: 'guo_jia_ji_nong_zuo_wu_pin_zhong_zong_shu',
          required: true,
          group: true,
        }, {
          type: 'number',
          label: '国家新药总数（项）',
          field: 'guo_jia_xin_yao_zong_shu',
          required: true,
          group: true,
        }, {
          type: 'number',
          label: '集成电路布图设计专有权总数（项）',
          field: 'ji_cheng_dian_lu_bu_tu_she_ji_zhuan_you_quan_zong_shu',
          required: true,
        },

        {
          type: 'radioChildren',
          label: '近3年是否获得国家级科技奖励',
          field: 'shi_fou_huo_de_guo_jia_ji_ke_ji_jiang_li',
          required: true,
          dataList: [
            {
              value: '否',
              text: '否',
            },
            {
              value: '是', text: '是',
              children: [
                {
                  type: 'number',
                  label: '年份',
                  field: 'shi_fou_huo_de_guo_jia_ji_ke_ji_jiang_li_year',
                  required_where: {
                    field: 'shi_fou_huo_de_guo_jia_ji_ke_ji_jiang_li',
                    value: '是',
                  },
                },
                {
                  type: 'text',
                  label: '名称',
                  field: 'shi_fou_huo_de_guo_jia_ji_ke_ji_jiang_li_name',
                  required_where: {
                    field: 'shi_fou_huo_de_guo_jia_ji_ke_ji_jiang_li',
                    value: '是',
                  },
                },
                {
                  type: 'number',
                  label: '排名',
                  field: 'shi_fou_huo_de_guo_jia_ji_ke_ji_jiang_li_pai_ming',
                  required_where: {
                    field: 'shi_fou_huo_de_guo_jia_ji_ke_ji_jiang_li',
                    value: '是',
                  },
                },
              ],
            },
          ],
        },
        {
          type: 'radioChildren',
          label: '近3年进入“创客中国”中小企业创新创业大赛全国50强企业组名单',
          field: 'chuang_ke_zhong_guo',
          required: true,
          dataList: [
            {
              value: '否',
              text: '否',
            },
            {
              value: '是',
              text: '是',
              children: [
                {
                  type: 'number',
                  label: '年份',
                  field: 'chuang_ke_zhong_guo_year',
                  required_where: {
                    field: 'chuang_ke_zhong_guo', value: '是',
                  },
                },
                {
                  type: 'text',
                  label: '排名',
                  field: 'chuang_ke_zhong_guo_name',
                  required_where: {
                    field: 'chuang_ke_zhong_guo', value: '是',
                  },
                },
              ],
            },
          ],
        },

        {
          type: 'radioChildren',
          label: '近2年是否承担过国家重大科技项目',
          field: 'shi_fou_cheng_dan_guo_guo_jia_zhong_da_ke_ji',
          required: true,
          dataList: [
            {
              value: '否',
              text: '否',
            },
            {
              value: '是',
              text: '是',
              children: [
                {
                  type: 'text',
                  label: '项目名称',
                  field: 'shi_fou_cheng_dan_guo_guo_jia_zhong_da_ke_ji_name',
                  required_where: {
                    field: 'shi_fou_cheng_dan_guo_guo_jia_zhong_da_ke_ji',
                    value: '是',
                  },
                },

              ],
            },
          ],
        },

        {
          type: 'radioChildren',
          label: '近2年是否获得过国家级技术创新类项目',
          field: 'fou_huo_de_guo_guo_jia_ji_ji_shu_chuang_xin',
          required: true,
          dataList: [
            {
              value: '否', text: '否',
            },
            {
              value: '是',
              text: '是',
              children: [
                {
                  type: 'text',
                  label: '项目名称',
                  field: 'fou_huo_de_guo_guo_jia_ji_ji_shu_chuang_xin_name',
                  required_where: {
                    field: 'fou_huo_de_guo_guo_jia_ji_ji_shu_chuang_xin',
                    value: '是',
                  },
                },
              ],
            },
          ],
        },
        {
          type: 'checkboxChildren',
          label: '技术创新相关荣誉',
          field: 'ji_shu_chuang_xin_xiang_guan_rong_yu',
          required: true,
          group: true,
          dataList: [
            {
              text: '高新技术企业',
              value: '高新技术企业',
            }, {
              text: '国家级-技术创新示范企业',
              value: '国家级-技术创新示范企业',
            }, {
              text: '省级-技术创新示范企业',
              value: '省级-技术创新示范企业',
            }, {
              text: '国家级-工业企业知识产权运用试点企业',
              value: '国家级-工业企业知识产权运用试点企业',
            }, {
              text: '省级-工业企业知识产权运用试点企业',
              value: '省级-工业企业知识产权运用试点企业',
            }, {
              text: '《产业基础领域先进技术产品转化应用目录》入编企业',
              value: '《产业基础领域先进技术产品转化应用目录》入编企业',
            }, {
              text: '其他',
              value: '其他',
              children: [
                {
                  type: 'text',
                  label: '其他（请说明获得相关部门认定的技术创新类荣誉称号）',
                  field: 'ji_shu_chuang_xin_xiang_guan_rong_yu_shuo_ming',
                  required_where: {
                    field: 'ji_shu_chuang_xin_xiang_guan_rong_yu',
                    value: '其他',
                  },
                },
              ],
            },
          ],

        },
      ],
    },
  },
  {
    name: '七、产业链配套',
    role: {
      isShowButton: false, properties: [
        {
          type: 'xian',
          label: '所属产业链',
        },
        {
          type: 'radio',
          label: '是否在产业链关键领域实现“补短板”“填空白”',
          field: 'chan_ye_lian_guan_jian_ling_yu_shi_xian_bu_duan_ban',
          required: true,
          group: true,
          dataList: [
            {
              text: '否',
              value: '否',
            },
            {
              text: '是',
              value: '是',
            },
          ],
        },

        {
          type: 'text',
          label: '如是，请填写：“补短板”的产品名称',
          group: true,
          field: 'chan_ye_lian_guan_jian_ling_yu_shi_xian_bu_duan_ban_name',
          required_where: chan_ye_lian_guan_jian_ling_yu_shi_xian_bu_duan_ban,
        }, {
          type: 'text',
          label: '如是，请填写：或填补国内（国际）空白的领域',
          group: true,
          field: 'chan_ye_lian_guan_jian_ling_yu_shi_xian_bu_duan_ban_tian_bu',
          required_where: chan_ye_lian_guan_jian_ling_yu_shi_xian_bu_duan_ban,
        }, {
          type: 'text',
          label: '如是，请填写：或替代进口的国外企业（或产品）名称',
          group: true,
          field: 'chan_ye_lian_guan_jian_ling_yu_shi_xian_bu_duan_ban_dai_ti',
          required_where: chan_ye_lian_guan_jian_ling_yu_shi_xian_bu_duan_ban,
        }, {
          type: 'textarea',
          label: '如是，请填写：说明（是否在细分领域实现关键技术首创等情况，300字以内）',
          field: 'chan_ye_lian_guan_jian_ling_yu_shi_xian_bu_duan_ban_dai_ti_shu_ming',
          required_where: {
            field: 'chan_ye_lian_guan_jian_ling_yu_shi_xian_bu_duan_ban',
            value: '是',
          },
          maxLength: 300,
        },
        {
          type: 'checkbox',
          label: '主导产品创新性情况（没有请在其他说明内写“无”）',
          field: 'zhu_dao_chan_pin_chuang_xin_xing_qing_kuang_1',
          dataList: [
            {
              text: '首台套（装备)',
              value: '首台套（装备)',
            },
            {
              text: '首版次（软件）',
              value: '首版次（软件）',
            },
            {
              text: '首批次（新材料）',
              value: '首批次（新材料）',
            },
            {
              text: '其他',
              value: '其他',
            },
          ],
          required: true,
          group: true,
        },
        {
          type: 'text',
          label: '其他(请说明获得相关部门认定的主导产品创新类称号)',
          field: 'zhu_dao_chan_pin_chuang_xin_xing_qing_kuang_1_shuo_ming',
          group: true,
          required_where: {
            field: 'zhu_dao_chan_pin_chuang_xin_xing_qing_kuang_1',
            value: '其他',
          },
        },
        {
          type: 'radio',
          label: '是否享受过国家首台（套）重大技术装备保险补偿试点政策',
          field: 'shi_fou_xiang_shou_guo_guo_jia_shou_tai',
          required: true,
          dataList: [
            {
              text: '是',
              value: '是',
            },
            {
              text: '否',
              value: '否',
            },
          ],
        },
        {
          type: 'radio',
          label: '主导产品是否为国内外知名大企业直接配套',
          field: 'zhu_dao_chan_pin_shi_fou_wei_guo_nei',
          required: true,
          group: true,
          dataList: [
            {
              text: '是',
              value: '是',
            },
            {
              text: '否',
              value: '否',
            },
          ],
        },
        {
          type: 'texts',
          label: '如是，请填写（知名企业名称）',
          field: 'zhu_dao_chan_pin_shi_fou_wei_guo_nei_1',
          maxNumber: 3,
          required_where: {
            field: 'zhu_dao_chan_pin_shi_fou_wei_guo_nei',
            value: '是',
          },
        },
      ],
    },
  },
  {
    name: '八、主导产品所属领域',
    role: {
      isShowButton: false, properties: [
        {
          type: 'text',
          label: '主导产品名称（中文）',
          field: 'zhu_dao_chan_pin_ming_cheng',
          required: true,
        }, {
          type: 'text',
          label: '主导产品类别',
          field: 'zhu_dao_chan_pin_lei_bie',
          required: true,
        },
        {
          type: 'texts',
          label: '行业领军企业（3个以内）',
          field: 'hang_ye_ling_jun_qi_ye',
          required: true,
          maxNumber: 3,
          group: true,
        },
        {
          type: 'radio',
          label: '主导产品是否属于工业“六基”领域3，或制造强国战略相关领域，或网络强国建设相关领域',
          field: 'huo_wang_luo_qiang_guo_jian_she_xiang_guan_ling_yu',
          required: true,
          group: true,
          dataList: [
            {
              value: '否',
              text: '否',
            },
            {
              value: '是',
              text: '是',
            },
          ],
        },
        {
          type: 'checkbox',
          label: '如是，请打勾',
          field: 'huo_wang_luo_qiang_guo_jian_she_xiang_guan_ling_yu_1',
          dataList: [
            {
              value: '核心基础零部件',
              text: '核心基础零部件',
            },
            {
              value: '核心基础元器件',
              text: '核心基础元器件',
            },
            {
              value: '关键软件',
              text: '关键软件',
            },
            {
              value: '先进基础工艺',
              text: '先进基础工艺',
            },
            {
              value: '关键基础材料',
              text: '关键基础材料',
            },
            {
              value: '产业技术基础',
              text: '产业技术基础',
            },
            {
              value: '制造强国战略十大重点产业领域',
              text: '制造强国战略十大重点产业领域',
            },
            {
              value: '网络强国建设的信息基础设施、关键核心技术、网络安全、数据安全领域',
              text: '网络强国建设的信息基础设施、关键核心技术、网络安全、数据安全领域',
            },
          ],
          required_where: {
            field: 'huo_wang_luo_qiang_guo_jian_she_xiang_guan_ling_yu',
            value: '是',
          },
        },
      ],
    },
  },
  {
    name: '九、企业“专精特新链品”指标情况一览表', role: {
      isShowButton: false, properties: [
        {
          type: 'xian',
          label: '（一）基本条件',
        },
        {
          type: 'radio',
          label: '1、(基础指标)根据《中小企业划型标准规定》（工信部联企业［2011]300号），企业规模属于中小企业',
          field: 'small_and_medium_sized_enterprise_classification_standards',
          required: true,
          dataList: select_radio_is,
          group: true,
        },
        {
          type: 'radio',
          label: '企业规模属于哪类企业',
          field: 'the_size_of_the_enterprise_belongs_to_that_enterprise',
          dataList: [
            {
              text: '大型',
              value: '大型',
            },

            {
              text: '中型',
              value: '中型',
            },
            {
              text: '小型',
              value: '小型',
            },
            {
              text: '微型',
              value: '微型',
            },
          ],
          required_where: {
            field: 'small_and_medium_sized_enterprise_classification_standards',
            value: '是',
          },

        },
        {
          type: 'radioChildren',
          label: '2、（基础指标）近三年未发生重大安全、质量、环境污染等事故以及偷漏税等违法违规行为',
          field: 'accidents_such_as_environmental_pollution_and_illegal_acts_such_as_tax_evasion',
          required: true,
          dataList: [
            {
              text: '是',
              value: '是',
              children: [
                {
                  type: 'number',
                  label: '近三年发生违法违规行为几起',
                  field: 'several_violations_occurred_in_the_past_three_years',
                  required_where: {
                    field: 'accidents_such_as_environmental_pollution_and_illegal_acts_such_as_tax_evasion',
                    value: '是',
                  },
                },
              ],
            },
            {
              text: '否',
              value: '否',
            },
          ],
        },
        {
          type: 'xian',
          label: '（二）专业化发展水平',
        },
        {
          type: 'radioChildren',
          label: '3、（基础指标）截至上年末，企业从事特定细分市场时间达到3年以上',
          field: 'companies_engage_in_specific_market_segments_for_a_period_of_time',
          required: true,
          dataList: [
            {
              text: '是', value: '是',
              children: [
                {
                  type: 'text',
                  label: '截至上年末，企业从事特定细分市场几年',
                  field: 'companies_engage_in_specific_market_segments',
                  required_where: {
                    field: 'companies_engage_in_specific_market_segments_for_a_period_of_time',
                    value: '是',
                  },
                },
              ],
            }, {
              text: '否', value: '否',
            },
          ],
          group: true,

        }, {
          type: 'radioChildren',
          label: '4、（基础指标）主营业务收入占营业收入比重不低于70%',
          field: 'the_proportion_of_main_business_income_in_operating_income',
          dataList: [
            {
              text: '是',
              value: '是',
              children: [
                {
                  type: 'number',
                  label: '主营业务收入占营业收入比重为(%)',
                  field: 'the_main_business_income_accounted_for_the_operating_income',
                  required_where: {
                    field: 'the_proportion_of_main_business_income_in_operating_income',
                    value: '是',
                  },
                },
              ],
            }, {
              text: '否', value: '否',
            },
          ],
          required: true,
        },
        {
          type: 'radioChildren',
          label: '5、（基础指标）近2年主营业务收入平均增长率不低于5％',
          field: 'revenue_of_wang_ying_business_in_recent_2_years',
          required: true,
          dataList: [
            {
              text: '是', value: '是',
              children: [
                {
                  type: 'number',
                  label: '近2年主营业务收入平均增长率(%)',
                  field: 'average_growth_rate_of_main_business_income',
                  required_where: {
                    field: 'revenue_of_wang_ying_business_in_recent_2_years',
                    value: '是',
                  },
                },
              ],
            }, {
              text: '否', value: '否',
            },
          ],
        },
        {
          type: 'xian',
          label: '（三）精细化发展水平',
        }, {
          type: 'radioChildren',
          label: '6、（基础指标）截至上年末，资产负债率不高于70%',
          field: 'the_asset_liability_ratio_is_not_higher_than_the_ratio',
          required: true,
          dataList: [
            {
              text: '是', value: '是',
              children: [
                {
                  type: 'number',
                  label: '截至上年末，资产负债率（%）',
                  field: 'asset_liability_ratio_of_last_year',
                  required_where: {
                    field: 'the_asset_liability_ratio_is_not_higher_than_the_ratio',
                    value: '是',
                  },
                },
              ],
            }, {
              text: '否', value: '否',
            },
          ],
        },
        {
          type: 'radioChildren',
          label: '7、（基础指标）取得相关管理体系认证，或产品通过发达国家和地区权威机构产品认证',
          field: 'obtain_relevant_management_system_certification',
          dataList: [
            {
              text: '是', value: '是',
              children: [
                {
                  type: 'number',
                  label: '取得管理体系认证几个',
                  field: 'obtain_relevant_management_system_certification_1',
                  required_where: {
                    field: 'obtain_relevant_management_system_certification',
                    value: '是',
                  },
                },
                {
                  type: 'number',
                  label: '取得发达国家和地区权威机构产品认证几个',
                  field: 'obtain_relevant_management_system_certification_2',
                  required_where: {
                    field: 'obtain_relevant_management_system_certification',
                    value: '是',
                  },
                },
              ],
            }, {
              text: '否', value: '否',
            },
          ],
          required: true,
        },
        {
          type: 'radioChildren',
          label: '8、（基础指标）至少1项核心业务采用信息系统支撑',
          field: 'the_core_business_is_supported_by_information_system',
          required: true,
          dataList: [
            {
              text: '是', value: '是',
              children: [
                {
                  type: 'number',
                  label: '几项核心业务采用信息系统支撑',
                  field: 'the_core_business_is_supported_by_information_system_1',
                  required_where: {
                    field: 'the_core_business_is_supported_by_information_system',
                    value: '是',
                  },
                },
              ],
            }, {
              text: '否', value: '否',
            },
          ],
        },
        {
          type: 'xian',
          label: '（四）特色化发展水平',
        },
        {
          type: 'radioChildren',
          label: '9、（基础指标）主导产品在全国细分市场占有率达10%以上，且享有较高知名度和影响力',
          field: 'and_enjoy_high_visibility_and_influence',
          required: true,
          dataList: [
            {
              text: '是', value: '是',
              children: [
                {
                  type: 'number',
                  label: '主导产品在全国细分市场占有率为（%）',
                  field: 'and_enjoy_high_visibility_and_influence_1',
                  required_where: {
                    field: 'and_enjoy_high_visibility_and_influence',
                    value: '是',
                  },
                },
              ],
            }, {
              text: '否', value: '否',
            },
          ],
        }, {
          type: 'radioChildren',
          label: '10、（基础指标）拥有直接面问市场并具有竞争优势的自主品牌1项以上',
          field: 'have_direct_access_to_the_market',
          required: true,
          dataList: [
            {
              text: '是', value: '是',
              children: [
                {
                  type: 'number',
                  label: '拥有直接面向市场并具有竞争优势的自主品牌几项',
                  field: 'have_direct_access_to_the_market_1',
                  required_where: {
                    field: 'have_direct_access_to_the_market',
                    value: '是',
                  },
                },
              ],
            }, {
              text: '否', value: '否',
            },
          ],
        },
        {
          type: 'xian',
          label: '（五）创新能力水平',
        },
        {
          type: 'radio',
          label: '11、（基础指标）研发投入占比根据营收情况必须满足以下条件之一：<br/>' +
            '（1）上年度营业收入总额在1 亿元以上的企业，近2年研发费用总额占营业收入总额比重均不低于3%；<br/>' +
            '（2）上年度营业收入总额在5000万元一1亿的企业，近2年研发费用总额占营业收入总额比重均不低于6%；<br/>' +
            '（3）上年度营业收入总额在5000万元以下的企业，且同时满足：近2年内新增股权融资总额8000万元以上，研发费用总额3000万元以上，研发人员占企业职工总数50%以上',
          field: 'the_proportion_of_rd_investment_is_based_on_revenue',
          required: true,
          dataList: select_radio_is,
          group: true,
        },
        {
          type: 'number',
          label: '上年度营业收入总额（亿元）（精确到小数点后两位）',
          field: 'the_proportion_of_rd_investment_is_based_on_revenue_1',
          group: true,
          required_where: {
            field: 'the_proportion_of_rd_investment_is_based_on_revenue',
            value: '是',
          },
        },
        {
          type: 'checkbox',
          label: '对照以上条件',
          field: '11_dui_zhao_tiao_jian',
          group: true,
          dataList: [
            {
              text: '（1）',
              value: '（1）'
            },
            {
              text: '（2）',
              value: '（2）'
            },
            {
              text: '（3）',
              value: '（3）'
            }
          ],
          required_where: {
            field: 'the_proportion_of_rd_investment_is_based_on_revenue',
            value: '是',
          },
        },
        {
          type: 'texts',
          label: '简要描述',
          field: '11_jian_yao_miao_shu',
          maxNumber: 5,
          required_where: {
            field: 'the_proportion_of_rd_investment_is_based_on_revenue',
            value: '是',
          },
        },
        {
          type: 'radioChildren',
          label: '12、（基础指标）自建或与高等院校、科研机构联合建立研发机构，设立技术研究院、企业技术中心、企业工程中心、工业设计中心、院士专家工作站、博士后工作站等研发机构',
          field: 'research_institutions_jointly_establish_research_and_development_institutions',
          required: true,
          dataList: [
            {
              text: '是', value: '是',
              children: [
                {
                  type: 'number',
                  label: '自建研发机构数量',
                  field: 'research_institutions_jointly_establish_research_and_development_institutions_1',
                  required_where: {
                    field: 'research_institutions_jointly_establish_research_and_development_institutions',
                    value: '是',
                  },
                },
                {
                  type: 'number',
                  label: '联合建立各类研发机构数量',
                  field: 'research_institutions_jointly_establish_research_and_development_institutions_2',
                  required_where: {
                    field: 'research_institutions_jointly_establish_research_and_development_institutions',
                    value: '是',
                  },
                },
              ],
            }, {
              text: '否', value: '否',
            },
          ],
        },
        {
          type: 'radioChildren',
          label: '13、（基础指标）拥有2项以上与主导产品有关的Ⅰ类知识产权，且实际应用并已产生经济效益',
          field: 'and_the_practical_application_has_produced_economic_benefits',
          required: true,
          dataList: [
            {
              text: '是', value: '是',
              children: [
                {
                  type: 'number',
                  label: '与主导产品有关的Ⅰ类知识产权几项，已实际应用并产生经济效益',
                  field: 'and_the_practical_application_has_produced_economic_benefits_1',
                  required_where: {
                    field: 'and_the_practical_application_has_produced_economic_benefits',
                    value: '是',
                  },
                },
              ],
            }, {
              text: '否', value: '否',
            },
          ],
        },
        {
          type: 'radio',
          label: '14、（创新直通车条件）创新直通车满足情况（满足以下条件之一）：<br/>' +
            '（1）近 3 年获得国家级科技奖励，并在获奖单位中排名前三；<br/>' +
            '（2）近 3 年进入“创客中国”中小企业创新创业大赛全国 50 强企业组名单',
          field: 'innovation_through_train_satisfaction',
          required: true,
          dataList: select_radio_is,
          group: true,
        },
        {
          type: 'checkbox',
          label: '如达标，请选择具体达标条件',
          field: 'innovation_through_train_satisfaction_1',
          dataList: [
            {
              value: '（1）',
              text: '（1）',
            },
            {
              value: '（2）',
              text: '（2）',
            },
          ],
          required_where: {
            field: 'innovation_through_train_satisfaction',
            value: '是',
          },
        }, {
          type: 'xian',
          label: '（六）产业链配套指标',
        }, {
          type: 'radio',
          label: '15、（基础指标）位于产业链关键环节，发挥“补短板”“填空白”“进口替代”等重要作用',
          field: 'located_in_the_key_link_of_the_industrial_chain',
          required: true,
          dataList: select_radio_is,
          group: true,
        },
        {
          type: 'textarea',
          label: '如是，请作简要描述',
          field: 'located_in_the_key_link_of_the_industrial_chain_1',
          required_where: {
            field: 'located_in_the_key_link_of_the_industrial_chain',
            value: '是',
          },
        }, {
          type: 'xian',
          label: '（七）主导产品所属领域指标',
        },
        {
          type: 'radioChildren',
          label: '16、（基础指标）主导产品属于工业“六基”领域，或制造强国战略相关领域，或网络强国建设相关领域',
          field: 'manufacturing_power_strategy_related_fields',
          required: true,
          dataList: [
            {
              text: '是', value: '是',
              children: [
                {
                  type: 'text',
                  label: '如是，如果主导产品属于工业“六基”，《产业基础创新发展目录(2021 年版)》中的产业和技术名称为',
                  field: 'manufacturing_power_strategy_related_fields_1',
                  required_where: {
                    field: 'manufacturing_power_strategy_related_fields',
                    value: '是',
                  },
                },
              ],
            }, {
              text: '否', value: '否',
            },
          ],
        },

        {
          type: 'xian',
          label: '（八）其他指标',
        }, {
          type: 'radioChildren',
          label: '17、（基础指标）作为主要起草单位主导或参与制修订国际标准、国家标准、行业标准1项以上',
          field: 'more_than_1_industry_standard',
          required: true,
          dataList: [
            {
              text: '是', value: '是',
              children: [
                {
                  type: 'number',
                  label: '作为主要起草单位主导或参与制修订国际标准、国家标准、行业标准几项',
                  field: 'more_than_1_industry_standard_1',
                  required_where: {
                    field: 'more_than_1_industry_standard',
                    value: '是',
                  },
                },
              ],
            },
            {
              text: '否', value: '否',
            },
          ],
        },
        {
          type: 'radioChildren',
          label: '18、（基础指标）自建或联合建立的研发机构中，省级以上研发机构不少于 1 个',
          field: 'institutions_above_the_provincial_level_shall_not_be_less_than',
          required: true,
          dataList: [
            {
              text: '是', value: '是',
              children: [
                {
                  type: 'number',
                  label: '现有省级以上研发机构几个',
                  field: 'institutions_above_the_provincial_level_shall_not_be_less_than_1',
                  required_where: {
                    field: 'institutions_above_the_provincial_level_shall_not_be_less_than',
                    value: '是',
                  },
                },
              ],
            }, {
              text: '否', value: '否',
            },
          ],

        },
        {
          type: 'radioChildren',
          label: '19、（基础指标）近2年承担过国家重大科技项目',
          field: 'undertaken_major_national_science_and_technology',
          required: true,
          dataList: [
            {
              text: '是', value: '是',
              children: [
                {
                  type: 'number',
                  label: '近 2 年承担过国家重大科技项目几项',
                  field: 'undertaken_major_national_science_and_technology_1',
                  required_where: {
                    field: 'undertaken_major_national_science_and_technology',
                    value: '是',
                  },
                },
              ],
            }, {
              text: '否', value: '否',
            },
          ],
        }, {
          type: 'radioChildren',
          label: '20、（基础指标）近2年获得过国家级技术创新类项目',
          field: 'national_technological_innovation_project',
          required: true,
          dataList: [
            {
              text: '是', value: '是',
              children: [
                {
                  type: 'number',
                  label: '近2年获得过国家级技术创新类项目几项',
                  field: 'national_technological_innovation_project_1',
                  required_where: {
                    field: 'national_technological_innovation_project',
                    value: '是',
                  },
                },
              ],
            }, {
              text: '否', value: '否',
            },
          ],
        },
        {
          type: 'radioChildren',
          label: '21、（基础指标）拥有制造业与互联网融合试点示范或智能制造试点示范项目',
          field: 'have_manufacturing_and_internet_finance',
          required: true,
          dataList: [
            {
              text: '是',
              value: '是',
              children: [
                {
                  type: 'text',
                  label: '项目名称：',
                  field: 'have_manufacturing_and_internet_finance_1',
                  required_where: {
                    field: 'have_manufacturing_and_internet_finance',
                    value: '是',
                  },
                },
              ],
            },
            {
              text: '否',
              value: '否',
            },
          ],
        },
        {
          type: 'radioChildren',
          label: '22、（基础指标）获得省级以上质量标杆认定',
          field: 'obtained_the_provincial_level_quality_benchmark_recognition',
          required: true,
          dataList: [
            {
              text: '是',
              value: '是',
              children: [
                {
                  type: 'number',
                  label: '获得省级以上质量标杆认定几项',
                  field: 'obtained_the_provincial_level_quality_benchmark_recognition_1',
                  required_where: {
                    field: 'obtained_the_provincial_level_quality_benchmark_recognition',
                    value: '是',
                  },
                },
              ],
            },
            {
              text: '否',
              value: '否',
            },
          ],

        },
        {
          type: 'radio',
          label: '23、（基础指标）主导产品为国内外知名大企业直接配套',
          field: 'leading_products_are_well_known_at_home_and_abroad',
          required: true,
          dataList: select_radio_is,
          group: true,
        },
        {
          type: 'textarea',
          label: '如是，请作简要描述',
          field: 'leading_products_are_well_known_at_home_and_abroad_instructions',
          required_where: {
            field: 'leading_products_are_well_known_at_home_and_abroad',
            value: '是',
          },
        },
      ],
    },
  },
];
