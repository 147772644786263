<script setup>
//时间选择 年月日
import {computed, defineEmit, defineProps, ref, watch} from "vue";
import XEUtils from "xe-utils";
import moment from "moment";

const showPicker = ref(false)
const emits = defineEmit(['update:modelValue'])
const props = defineProps({
  required: Boolean,
  option: Object,
  modelValue: [Number, String]
})

const selectValue = ref(moment().toDate())

//false = 禁用
const isJinYong = computed(() => {
  return !XEUtils.get(props, 'option.option.disabled') && !XEUtils.get(props, 'option.option.readonly')
})

function onConfirm(val) {
  console.log('val', val)
  showPicker.value = false
  console.log(moment(val).format('YYYY-MM-DD'))
  emits('update:modelValue', moment(val).format('YYYY-MM-DD'))
}

watch(() => props.modelValue, () => {
  if(moment(props.modelValue).unix() === moment(selectValue.value).unix()) return;
  if (XEUtils.isEmpty(props.modelValue)) {
    selectValue.value = null
    return
  }
  //不是时间 就直接清空
  if (moment(props.modelValue).isValid()) {
    selectValue.value = moment(props.modelValue).toDate()
    return
  }
}, {
  immediate: true
})
const minDate =  moment().subtract(100,'year').toDate()
const maxDate =  moment().add(10,'year').toDate()

const showVal = computed(() => {
  if (!XEUtils.isEmpty(props.modelValue)) {
    return moment(props.modelValue).format('YYYY-MM-DD')
  }
  if (moment(props.modelValue).isAfter()) {
    return moment(props.modelValue).format('YYYY-MM-DD')
  }
  return ''
})

</script>

<template>
  <van-field
      v-model="showVal"
      v-bind="props.option.option"
      :placeholder="props.option.placeholder"
      readonly
      :required="props.required"
      @click="showPicker = true"
      :name="props.option.field"
  >
  </van-field>
  <van-popup v-model:show="showPicker" position="bottom" v-if="isJinYong">
    <van-datetime-picker
        type="date"
        v-model="selectValue"
        @confirm="onConfirm"
        @cancel="showPicker = false"
        :min-date="minDate"
        :max-date="maxDate"
    />
  </van-popup>

</template>

<style scoped>

</style>