<script setup>
import {onMounted, ref} from "vue";
import to from "await-to-js";
import {get} from "@/api/http";
import XEUtils from "xe-utils";

const image = ref('http://yunzuofile.sxsgky.com/jpg/2024111409241848487.jpg')
onMounted(async ()=>{
  // const [, da] = await to(get('/gzhphp/shou_ji_info/get_image'))
  // image.value = XEUtils.get(da, 'data.url')
})

</script>

<template>
  <div class="tal-p-2 tal-bg-white tal-pb-0 ">
    <van-image class="yuan-jiao"  v-if="image" :src="image"/>
  </div>
</template>

<style scoped lang="less">
.yuan-jiao {
  border-radius: 8px;
  overflow: hidden;
  width: 100%;
}
</style>