<script setup>
import {defineEmit, defineProps, ref} from 'vue'
import XEUtils from "xe-utils";
import UAParser from 'ua-parser-js'
import AlertInfo from "@/views/shouJiInfo2/components/AlertInfo.vue";

const emit = defineEmit(['reset'])
const props = defineProps({
  info: Object
})

function reset() {
  emit('reset')
}

const cssType = new UAParser().getDevice().type

const refAlertInfo = ref(null)

function showTianXie() {
  refAlertInfo.value.show()
}
</script>

<template>
  <div
      class="tal-max-w-screen-sm tal-m-auto tal-h-screen tal-bg-white tal-flex tal-flex-col"
  >
    <div class="shou-ji-info-bg van-ellipsis tal-w-full tal-text-center">
      {{ XEUtils.get(props.info, 'corporation_name') }}
    </div>
    <div class="jin-ru-zhuang-tai tal-text-center">
      <span>
        已成功进入专精特效诊断系统
        <div class="xiao-tiao"></div>
      </span>
    </div>
<!--    <div class="fen-lei-tong-ji">-->
<!--      <div>-->
<!--        <div class="zong-fen-shu tal-mb-2">总分：<span>{{ XEUtils.get(props.info, 'fen_shu', '0') }}</span>分</div>-->
<!--        <div class="dan-qian tal-mb-2">企业当前梯度：{{-->
<!--            XEUtils.get(props.info, 'join_ti_du_gui_ze_id.name', '入库中')-->
<!--          }}-->
<!--        </div>-->
<!--      </div>-->
<!--      <div>-->
<!--        <img-->
<!--            class=" has-focus"-->
<!--            src="http://yunzuofile.sxsgky.com/cea00a9c2378b632ba6ba4df300c50061.png"-->
<!--        />-->
<!--      </div>-->
<!--    </div>-->
<!--    <div-->
<!--        class="cha-kan-nei-rong tal-pt-8 tal-text-center tal-cursor-pointer"-->
<!--        v-if="cssType ==='mobile'"-->
<!--    >-->
<!--      <span class="tal-p-2 tal-pl-6 tal-pr-6" @click="showTianXie">-->
<!--        查看已填写内容-->
<!--      </span>-->
<!--    </div>-->
    <div class="tal-bg-white tal-h-24 tal-flex tal-items-center ">
      <div class="tal-w-screen tal-flex tal-items-center tal-justify-center tal-space-x-20">
          <span
              style="margin-top: 50px"
              @click="reset"
              class="tal-p-3  tal-pl-10 tal-cursor-pointer tal-pr-10 tal-text-2xl tal-border-solid tal-border  tal-border-zhu-se tal-text-xl tal-rounded-full tal-bg-zhu-se tal-text-white">
              重新填写
            </span>
      </div>
    </div>
  </div>
  <alert-info ref="refAlertInfo"/>
</template>

<style scoped lang="less">
@basis-size: 14px;
@shouJiId: #104868;
.jin-ru-zhuang-tai {
  color: @shouJiId;

  span {
    font-size: 20px;
    position: relative;
    padding-right: 5px;
    padding-left: 5px;

    .xiao-tiao {
      height: 6px;
      width: 100%;
      position: absolute;
      bottom: -5px;
      left: 0;
      border-radius: 50px;
      background-color: #D1DCE2;
    }
  }
}

.cha-kan-nei-rong {
  span {
    font-size: 16px;
    border-radius: 100px;
    background-color: #3277BB;
    color: #fff;
  }
}

.fen-lei-tong-ji {
  margin-top: -9px;
  padding-left: 40px;
  padding-right: 1.25rem;
  display: flex;
  flex-direction: row;
  align-content: flex-start;
  justify-content: space-between;
  align-items: flex-end;
  font-size: 15px;
  color: @shouJiId;

  .zong-fen-shu {
    span {
      font-size: 28px;
      font-weight: bold;
    }
  }

  .has-focus {
    width: 80px;
  }
}

.ti-shi {
  padding-left: 40px;
  padding-right: 10px;
  color: #908F8F;
}

.ti-shi-div {
  padding-left: 28px;
  padding-right: 28px;
  font-size: 18px;

  .ti-shi {
    color: @shouJiId;
    opacity: .6;
  }
}

.shou-ji-info-bg {
  font-size: 28px;
  padding-right: 32px;
  padding-left: 32px;
  padding-top: 20px;
  padding-bottom: 20px;
  background-image: -webkit-linear-gradient(bottom, red, #0062B5, #3ec7e7);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.page {
  position: relative;
  //background-image: url("http://yunzuofile.sxsgky.com/default/20230303/88c4fdfb8cdd1c04d0ad37f47259a7a1.png");
  background-size: 100% auto;
  background-position-y: bottom;
  background-repeat: no-repeat;
  padding-bottom: 10px;


  .tong-ji {
    padding-top: 12px;
    padding-bottom: 6px;
    display: flex;
    flex-direction: row;
    align-content: flex-end;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .name {
      padding-left: 28px;
      font-size: @basis-size;
      color: @shouJiId;
    }

    .zong-fen-shu {
      font-size: 28px;
      font-weight: bold;
    }

    .xiao-ren-tou {
      width: 100px;
      height: 100px;
    }
  }

  .box_1 {
    margin-bottom: 20px;

    .group_1 {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-content: flex-start;
      justify-content: space-evenly;
      align-items: flex-start;
      color: @shouJiId;

      .text_1 {
        white-space: break-spaces;
        width: 30px;
        color: rgba(16, 72, 104, 1);
        font-size: 16px;
        font-weight: 500;
        padding-top: 8px;
      }

      .text-wrapper_1 {
        background-color: rgba(217, 226, 233, 1);
        font-size: 15px;
        padding: 10px 10px;
        flex: 1;
        display: flex;
        align-content: center;
        align-items: stretch;

        .text_2 {
          //padding-right: 10px;
        }

        .text_3 {
          flex: 1;
        }

        .text_4 {
          text-align: right;
          padding-left: 5px;
          display: flex;
          flex-direction: row;
          align-content: space-around;
          align-items: center;
          justify-content: flex-end;
          position: relative;
          width: 100px;

          .fen-shu {
            position: absolute;
            top: 50%;
            width: 100%;
            right: 0;
            transform: translateY(-18px);

            .number {
              font-size: 24px;
            }
          }
        }
      }
    }

    .text-wrapper_2 {
      display: flex;
      padding-left: 30px;
      padding-top: 16px;
      font-size: 14px;

      .text_4 {
        font-size: @basis-size;
        color: #104868;
      }

      .text_5 {
        flex: 1;
        color: rgba(16, 72, 104, 1);
        font-size: @basis-size;
        text-align: left;
        margin-bottom: 12px;
      }

      .text_6 {
        width: 287px;
        height: 33px;
        overflow-wrap: break-word;
        color: rgba(16, 72, 104, 1);
        font-size: 24px;
        font-weight: 500;
        text-align: left;
        white-space: nowrap;
        line-height: 33px;
      }
    }

    .text-wrapper_7 {
      display: flex;

      .text_11 {
        padding-left: 35px;
        //width: 120px;
        color: rgba(16, 72, 104, 1);
        font-size: @basis-size;
      }

      .text_12 {
        white-space: break-spaces;
        flex: 1;
        color: rgba(16, 72, 104, 1);
        font-size: @basis-size;
        text-align: left;
      }
    }
  }
}
</style>