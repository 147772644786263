<template>
  <van-action-sheet v-model:show="status" style="height: 70%;" title="详细内容">
    <div class="tal-w-full">
      <table class="table table-bordered ">
        <tbody>
        <tr>
          <td class="tal-break-all" style="width: 130px;">企业名称</td>
          <td class="tal-break-all" colspan="3">111</td>
        </tr>

        </tbody>
      </table>
    </div>
  </van-action-sheet>
</template>

<script>
import {ref} from 'vue'

export default {
  name: "AlertInfo",
  setup() {
    const status = ref(false)
    const info = ref({})

    async function show() {
      status.value = true
    }

    return {
      info,
      show,
      status
    }
  }
}
</script>

<style scoped>

</style>
