<template>
  <van-field
      v-bind="props.option.option"
      :placeholder="props.option.placeholder"
      readonly
      :required="props.required"
      :name="props.option.field"
  >
    <template #input>
      <van-radio-group v-model="selectValue" >
        <div class="tal-grid tal-grid-cols-2 tal-gap-4">
          <div v-for="(vo,index) in columns">
            <van-radio :name="vo.value" >{{vo.text}}</van-radio>
          </div>
        </div>
      </van-radio-group>
    </template>
  </van-field>
</template>

<script setup>
//单选框
import {computed, defineEmit, defineProps, onMounted, ref, watchEffect,watch} from "vue";
import XEUtils from "xe-utils";
const emits = defineEmit(['update:modelValue'])
const props = defineProps({
  required:Boolean,
  option: Object,
  modelValue: [Number, String]
})
const selectValue = ref('')


//同步上级
watch(()=>selectValue.value,()=>{
  emits('update:modelValue',selectValue.value)
})
//同步本组件
watch(()=>props.modelValue,n=>{
   if(props.modelValue !== selectValue.value){
     selectValue.value = props.modelValue
   }
},{
  immediate:true
})


//false = 禁用
const isJinYong = computed(()=>{
  return  !props.option.option.disabled && !props.option.option.readonly
})
/**
 * 检测是不是异步获取 true=异步
 * @returns {boolean}
 */
function isYiBu() {
  return !XEUtils.isUndefined(XEUtils.get(props.option, 'method'))
}

const columns = computed(() => {
  return XEUtils.get(props.option, 'dataList', [])
})
</script>

<style scoped>

</style>