<template>
  <div class="hong-dian">
    <van-form v-bind="formProp">
      <van-cell-group>
        <template v-for="(item,index) in getInputList" :key="index">
          <!--    单行文本    -->
          <div class="input-item" :class="{'group':item.group}" :id="`input-${item.field}`" v-if="item.type === 'text'">
            <div class="tal-pl-5 tal-pr-5">
              <div class="label-border">{{ item.label }}</div>
            </div>
            <van-field
                v-model="formValue[item.field]"
                v-bind="item.option"
                :name="item.field"
                :required="item.required"
                :placeholder="item.placeholder"
            />
          </div>
          <div class="input-item" :class="{'group':item.group}" :id="`input-${item.field}`"
               v-if="item.type === 'texts'">
            <div class="tal-pl-5 tal-pr-5">
              <div class="label-border">{{ item.label }}</div>
            </div>
            <texts
                :required="item.required"
                v-model="formValue[item.field]"
                :option="item"
                :maxNumber="item.maxNumber"
            />
          </div>
          <div class="input-item" :class="{'group':item.group}" :id="`input-${item.field}`"
               v-if="item.type === 'number'">
            <div class="tal-pl-5 tal-pr-5">
              <div class="label-border">{{ item.label }}</div>
            </div>
            <van-field
                type="number"
                v-model="formValue[item.field]"
                v-bind="item.option"
                :required="item.required"
                :placeholder="item.placeholder"
                :name="item.field"
            />
          </div>
          <!--    多行文本      -->
          <div class="input-item" :class="{'group':item.group}" :id="`input-${item.field}`"
               v-if="item.type === 'textarea'">
            <div class="tal-pl-5 tal-pr-5">
              <div class="label-border">{{ item.label }}</div>
            </div>
            <van-field
                v-model="formValue[item.field]"
                v-bind="item.option"
                :required="item.required"
                :placeholder="item.placeholder"
                type="textarea"
                rows="2"
                :show-word-limit="item.maxLength"
                :maxlength="item.maxLength"
                :name="item.field"
            />
          </div>

          <!--    密码    -->
          <van-field
              v-model="formValue[item.field]"
              v-bind="item.option"
              type="password"
              :label="item.label"
              :required="item.required"
              :placeholder="item.placeholder"
              v-if="item.type === 'password'"
              :name="item.field"
          />
          <!--     单选下拉     -->
          <dan-xuan
              :required="item.required"
              v-model="formValue[item.field]"
              v-model:row="formValue[item.field+'_row']"
              :option="item"
              v-else-if="item.type === 'select'"
          />
          <!--     地址选择     -->
          <div class="input-item" :class="{'group':item.group}" :id="`input-${item.field}`"
               v-else-if="item.type === 'selectArea'">
            <div class="tal-pl-5 tal-pr-5">
              <div class="label-border">{{ item.label }}</div>
            </div>
            <select-area
                :required="item.required"
                v-model="formValue[item.field]"
                v-model:name="formValue[item.field+'_name']"
                :option="item"
            />
          </div>
          <div class="fen-ge-xian" v-else-if="item.type === 'xian'">
            {{ item.label }}
          </div>
          <div class="fen-ge-min-xian" v-else-if="item.type === 'min-xian'"/>
          <!--     复选框    -->

          <div class="input-item" :class="{'group':item.group}" :id="`input-${item.field}`"
               v-else-if="item.type === 'checkbox'">
            <div class="tal-pl-5 tal-pr-5">
              <div class="label-border">{{ item.label }}</div>
            </div>
            <lol-checkbox
                :required="item.required"
                v-model="formValue[item.field]"
                :option="item"
                :maxNumber="item.maxNumber"
            />
          </div>
          <!--     复选框 +子组件   -->
          <div class="input-item" :class="{'group':item.group}" :id="`input-${item.field}`"
               v-else-if="item.type === 'checkboxChildren'">
            <div class="tal-pl-5 tal-pr-5">
              <div class="label-border">{{ item.label }}</div>
            </div>
            <lol-checkbox-children
                :required="item.required"
                v-model="formValue[item.field]"
                :option="item"
                :maxNumber="item.maxNumber"
                :form-value="formValue"
                @children="radioChildren"
            />
          </div>
          <!--     单选框    -->
          <div class="input-item" :class="{'group':item.group}" :id="`input-${item.field}`"
               v-else-if="item.type === 'radio'">
            <div class="tal-pl-5 tal-pr-5">
              <div class="label-border" v-html="item.label"></div>
            </div>
            <lol-radio
                :required="item.required"
                v-model="formValue[item.field]"
                :option="item"
                :maxNumber="item.maxNumber"

            />
          </div>
          <!--     单选框+子组件   -->
          <div class="input-item" :class="{'group':item.group}" :id="`input-${item.field}`"
               v-else-if="item.type === 'radioChildren'">
            <div class="tal-pl-5 tal-pr-5">
              <div class="label-border">{{ item.label }}</div>
            </div>
            <lol-radio-children
                :required="item.required"
                v-model="formValue[item.field]"
                :option="item"
                :maxNumber="item.maxNumber"
                @children="radioChildren"
                :form-value="formValue"
            />
          </div>


          <!--     时间选择 年月日    -->
          <div class="input-item" :class="{'group':item.group}" :id="`input-${item.field}`"
               v-else-if="item.type === 'ymd'">
            <div class="tal-pl-5 tal-pr-5">
              <div class="label-border">{{ item.label }}</div>
            </div>
            <ymd
                :required="item.required"
                v-model="formValue[item.field]"
                :option="item"
                :maxNumber="item.maxNumber"
            />

          </div>
        </template>

      </van-cell-group>
    </van-form>
    <div class="tal-p-10" v-if="isShowButton">
      <slot name="submit">
        <van-button
            type="primary"
            :loading="submitButtonLoading"
            block
            @click="submit"
            :disabled="submitButtonDisabled"
        >
          {{ btnText }}
        </van-button>
      </slot>
    </div>
  </div>
</template>

<script setup>

import {computed, defineEmit, useContext, defineProps, ref, watch, provide} from 'vue'
import XEUtils from "xe-utils";
import DanXuan from "@/components/form/danXuan.vue";
import SelectArea from "@/components/form/selectArea.vue";
import {showFailToast} from "vant";
import LolCheckbox from "@/components/form/LolCheckbox.vue";
import LolRadio from "@/components/form/LolRadio.vue";
import to from "await-to-js";
import Schema from "async-validator";
import Ymd from "@/components/form/ymd.vue";
import Texts from "@/components/form/Texts.vue";
import LolRadioChildren from "@/components/form/LolRadioChildren.vue";
import LolCheckboxChildren from "@/components/form/LolCheckboxChildren.vue";


const emit = defineEmit(['update:modelValue', 'submit'])
const props = defineProps({
  submitButtonLoading: { //保存按钮状态
    type: Boolean
  },
  submitButtonDisabled: { //保存按钮状态
    type: Boolean
  },
  // 表单字段
  properties: {
    type: Array,
    default: []
  },
  formProp: {
    type: Object,
    default: {}
  }, //表单配置
  modelValue: {
    type: Object,
    default: {}
  }, //表单值
  required: { //必填字段
    type: Array,
    default() {
      return []
    }
  },
  validate: { //添加验证
    type: Object,
    default: () => {
      return {}
    }
  },
  btnText: { //按钮文字
    type: String,
    default: '保存'
  },
  //是否显示提交按钮
  isShowButton: {
    type: Boolean,
    default: true
  }
})
const formValue = ref({})
provide('formValue', formValue)
const getInputList = computed(() => {
  const list = XEUtils.isArray(props.properties) ? props.properties : []
  return XEUtils.map(list, n => {
    if (XEUtils.isUndefined(n.option)) {
      n.option = {}
    }
    if (props.required.includes(n.field)) {
      n.required = true
    }


    switch (n.type) {
      case 'password':
      case 'text':
      case 'texts':
      case 'number':
      case 'textarea':
        if (XEUtils.isUndefined(n.placeholder)) {
          n.placeholder = `请输入${n.label}`
        }
        break;
      case 'selectArea':
      case 'select':
      case 'radio':
      case 'checkbox':
      case 'ymd':
      case 'selectUser':
        if (XEUtils.isUndefined(n.placeholder)) {
          n.placeholder = `请选择${n.label}`
        }
        break;
      case 'file':
      case 'avatar':
        if (XEUtils.isUndefined(n.placeholder)) {
          n.placeholder = `请上传${n.label}`
        }
        break
    }
    return n
  })
})

watch(() => formValue.value, () => {
  if (XEUtils.toJSONString(props.modelValue) !== XEUtils.toJSONString(formValue.value)) {
    emit('update:modelValue', formValue.value)
  }
}, {
  deep: true,
})
watch(() => props.modelValue, () => {
  if (XEUtils.toJSONString(props.modelValue) !== XEUtils.toJSONString(formValue.value)) {
    formValue.value = props.modelValue
  }
}, {
  deep: true,
  immediate: true
})

function validate() {
  return new Promise((resolve, reject) => {
    const err = []
    XEUtils.each(props.required, n => {
      const find = XEUtils.find(props.properties, m => {
        return n === m.field
      })
      const val = XEUtils.get(formValue.value, n)
      if (XEUtils.isUndefined(val)) {
        err.push(XEUtils.get(find, 'placeholder', '请输入'))
        return
      }
      if ((XEUtils.isArray(val) || XEUtils.isObject(val) || XEUtils.isString(val)) && XEUtils.isEmpty(val)) {
        err.push(XEUtils.get(find, 'placeholder', '请输入'))
        return
      }
    })


    if (XEUtils.isEmpty(err)) {
      resolve(formValue.value)
      return;
    }


    showFailToast(err[0])
    reject(err)
  })
}

async function submit() {
  const [e, status] = await to(validate())
  if (e) return
  const yan_zheng = new Schema(props.validate)
  const [err] = await to(yan_zheng.validate(formValue.value))
  if (err) {
    showFailToast(XEUtils.get(err, 'errors.0.message'))
    return
  }
  emit('submit')

}

function radioChildren({key, value}) {
  formValue.value[key] = value
}

useContext().expose({
  validate
})

</script>

<style scoped lang="less">
.input-item {
  border-bottom: 5px #f5f5f5 solid;

  &.group {
    border-bottom: 1px #f5f5f5 solid;
  }
}

.label-border {
  padding: 5px 2px 0;
}

.fen-ge-xian {
  padding: 10px;
  background: #f5f5f5;
}

.fen-ge-min-xian {
  height: 5px;
  background: #f5f5f5;

}

</style>