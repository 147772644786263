import Schema from 'async-validator';
import XEUtils from 'xe-utils';
import moment from 'moment';
import { areaList } from '@/assets/js/zhe_jiang_vant-area-data';

/**
 *
 * @param list //检测列表
 * @param val  //检测关键词
 * @returns boolean true=存在
 */
function jianCeStr(list, val) {
  if (XEUtils.isString(list)) {
    list = list.split(',');
  }
  return XEUtils.includes(list, val);
}

/**
 * @param n
 * @param ule
 * @param value
 * @param callback
 * @param source
 * @param options
 * @returns {boolean} true = 这里通过  其他的不通过
 */
function required_where_validate(n, kai_tou_title, value, options) {
  let fields = XEUtils.get(n, 'required_where.set_validate_fiend', [n.field]); //获取验证的字段
  if (!XEUtils.isArray(fields)) {
    fields = [fields];
  }
  let tian_xie = 0;//填写内容的有几个字段
  const min_tian_xie_num = XEUtils.get(n, 'required_where.required_num', 1);//至少填写几个
  //查找有几个填写内容了

  XEUtils.each(fields, (fnd) => {
    let v = XEUtils.get(options, ['form', fnd]);
    if (XEUtils.isNumber(v)) {
      v = XEUtils.toString(v);
    }
    if (XEUtils.isNumber(v) || XEUtils.isEmpty(undefined)) {
      v = XEUtils.toString(v);
    }
    if (!XEUtils.isEmpty(v)) {
      tian_xie++;
    }
  });

  const jian_ce_val = XEUtils.get(options,
    ['form', XEUtils.get(n, ['required_where', 'field'])]);
  if (jianCeStr(jian_ce_val, XEUtils.get(n, ['required_where', 'value'])) &&
    tian_xie < min_tian_xie_num) {
    return XEUtils.get(n, 'required_where.message', kai_tou_title[n.type] + n.label);
  } else {
    return true;
  }
}

export function formValidate(properties) {

  const properties_list = [];

  XEUtils.each(properties, n => {
    properties_list.push(n);
    XEUtils.each(n.dataList, n2 => {
      XEUtils.each(n2.children, n3 => {
        properties_list.push(n3);
      });
    });
  });

  const field = {};
  XEUtils.each(properties_list, (n) => {
    const kai_tou_title = {
      radio: '请选择',
      radioChildren: '请选择',
      checkbox: '请选择',
      checkboxChildren: '请选择',
      selectArea: '请选择',
      ymd: '请选择',
      textarea: '请输入',
      text: '请输入',
      hidden: '请输入',
      texts: '请输入',
      number: '请输入',
    };
    let con = {};

    if (n.required && !n.validate) {
      con.validator = (ule, value, callback, source, options) => {

        if (!XEUtils.isNumber(value) && XEUtils.isEmpty(value)) {

          callback(kai_tou_title[n.type] + n.labe);
          return;
        }
        callback();
      };
      switch (n.type) {
        case 'number':
          con = {
            ...con,
            type: 'string',
            required: true,
            message: kai_tou_title[n.type] + n.label,
            transform(val) {
              return XEUtils.toString(val);
            },
          };
          break;
        default:
          con = {
            ...con,
            type: 'string',
            required: true,
            message: kai_tou_title[n.type] + n.label,
          };
      }

    }
    else if (!XEUtils.isEmpty(n.required_where)) {
      con.validator = (ule, value, callback, source, options) => {
        const status = required_where_validate(n, kai_tou_title, value,
          options);
        if (status !== true) {
          callback(status);
          return;
        }
        callback();
      };
    }
    if (n.validate) {
      //覆盖自定验证
      con = {
        ...con,
        ...n.validate,
      };
    }

    if (!XEUtils.isEmpty(con)) {
      field[n.field] = con;
    }
    if(n.field === 'shen_bao_qi_ye_ming_cheng'){
      console.log(con);
    }
  });
  console.log(field);
  return new Schema(field);
}
