<template>
  <van-field
      v-bind="props.option.option"
      :label="props.option.label"
      :placeholder="props.option.placeholder"
      @click="showPicker = true"
      v-model="getSelectData"
      :name="props.option.field"
      readonly
      :required="props.required"
  >
    <template #right-icon>
      <van-icon v-if="isJinYong" name="arrow-down"/>
    </template>
  </van-field>
  <van-popup v-model:show="showPicker" v-if="isJinYong" position="bottom">
    <van-picker
        :columns="columns"
        @confirm="onConfirm"
        @cancel="showPicker = false"
    />
  </van-popup>
</template>

<script setup>
import {defineProps, ref, computed, onMounted,defineEmits} from 'vue'
import {useRequest} from "alova";
import XEUtils from 'xe-utils'
const emits = defineEmits(['update:modelValue'])
const showPicker = ref(false) //是否显示选择
const selectValue = ref(null)
const props = defineProps({
  required:Boolean,
  option: Object,
  modelValue: [Number, String]
})

function onConfirm(item) {
  showPicker.value = false
  let val = XEUtils.map(item.selectedOptions,n=>{
    return n.value
  }).join(',')
  if(/^-?\d+(\.\d+)?$/.test(val)){
    val = XEUtils.toNumber(val)
  }
  emits('update:modelValue',val)
  emits('update:row',item.selectedOptions[0])
}

const {data, send} = useRequest(() => {
  console.log(3333,XEUtils.get(props.option, 'method'))
  return XEUtils.get(props.option, 'method')
}, {
  immediate: false,
  initialData: []
})
//false = 禁用
const isJinYong = computed(()=>{
  return  !props.option.option.disabled && !props.option.option.readonly
})
/**
 * 检测是不是异步获取 true=异步
 * @returns {boolean}
 */
function isYiBu() {
  return !XEUtils.isUndefined(XEUtils.get(props.option, 'method'))
}

onMounted(() => {
  console.log('isYiBu()',isYiBu())
  if (isYiBu()) {
    send()
  }
})
const columns = computed(() => {
  if (isYiBu()) {
    return data.value
  }
  return XEUtils.get(props.option, 'dataList', [])
})

//获取选中的数据
const getSelectData = computed(()=>{
   let data = XEUtils.filter(columns.value,n=>{
     return XEUtils.toString(n.value) === XEUtils.toString(props.modelValue)
   })
  return XEUtils.map(data,n=>n.text).join(',')
})
</script>

<style scoped>

</style>