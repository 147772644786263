<template>
  <van-field
      v-bind="props.option.option"
      :placeholder="props.option.placeholder"
      readonly
      :required="props.required"
      :name="props.option.field"
  >
    <template #input>
      <div>
        <van-checkbox-group v-model="selectValue">
          <div class="zong-bu-ju" v-for="(vo,index) in columns" :key="index">
            <div>
              <van-checkbox :name="vo.value" shape="square" />
            </div>
            <div class="text-input-bu-ju">
              <span>
                {{ vo.text }}
              </span>
              <div class="input-div" v-if="vo.children &&  selectValue.includes(vo.value)">
                <div :id="`input-${v2.field}`" class="input-table" v-for="(v2,i2) in vo.children" :key="i2">
                  <span>{{ v2.label }}</span>
                  <input
                      :type="v2.type"
                      :placeholder="`请输入${v2.label}`"
                      v-model="children_val[v2.field]"
                      class="input"
                  />
                </div>
              </div>
            </div>
          </div>
        </van-checkbox-group>
      </div>
    </template>
  </van-field>
  <div class="duo-xuan">
    <van-action-sheet v-model:show="infoStatus.status" title="权限">
      <div class="quan-xian-content">
        <van-list>
          <van-cell v-for="(item,i) in infoStatus.content" :key="i" :title="item"/>
        </van-list>
      </div>
    </van-action-sheet>
  </div>
</template>

<script setup>
//多选框
import {computed, defineEmit, defineProps, onMounted, reactive, ref, watch} from "vue";
import XEUtils from "xe-utils";

const emits = defineEmit(['update:modelValue'])
const props = defineProps({
  required: Boolean,
  option: Object,
  modelValue: [Number, String],
  formValue: Object
})
const selectValue = ref([])
const children_val = ref({}) //子内容输入
const infoStatus = reactive({
  content: [1, 2, 4],
  status: false
})

onMounted(() => {
  //获取所有字段
  XEUtils.each(columns.value, n => {
    XEUtils.each(n.children, n2 => {
      if (XEUtils.isUndefined(children_val.value[n2.field])) {
        children_val.value[n2.field] = ''
      }
    })
  })

  watch(() => props.formValue, () => {
    //对比内容
    XEUtils.objectEach(children_val.value, (v, k) => {
      const val = XEUtils.isUndefined(props.formValue[k]) ? '' : props.formValue[k] //获取form的数据
      if (val !== v) {
        children_val.value[k] = val
      }
    })
  }, {
    deep: true,
    immediate: true
  })

  watch(() => children_val.value, () => {
    XEUtils.objectEach(children_val.value, (value, key) => {
      emits('children', {value, key})
    })
  }, {
    deep: true
  })
})

//同步上级
watch(() => selectValue.value, () => {
  emits('update:modelValue', selectValue.value.join(','))
})
//同步本组件
watch(() => props.modelValue, n => {

  if (props.modelValue !== selectValue.value.join(',')) {
    const val = XEUtils.toString(props.modelValue).split(',')
    selectValue.value = XEUtils.filter(val, n => !XEUtils.isEmpty(n))
  }
}, {
  immediate: true
})


//false = 禁用
const isJinYong = computed(() => {
  return !props.option.option.disabled && !props.option.option.readonly
})

/**
 * 检测是不是异步获取 true=异步
 * @returns {boolean}
 */



const columns = computed(() => {
  return XEUtils.map(XEUtils.get(props.option, 'dataList', []), n => {
    n.value = XEUtils.toString(n.value)
    return n
  })
})




</script>

<style scoped lang="less">
.quan-xian-content {
  max-height: 300px;
  overflow-y: auto;
}

.w-100 {
  width: 100%;
}

.zong-bu-ju {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 5px;
}

.text-input-bu-ju {
  padding-left: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex: 1;

  .input-div {
    flex: 1;
  }

  .input-table {
    padding-left: 10px;
    display: flex;
    flex-direction: column;
    align-content: flex-start;

    .input {
      background-color: #1469bd00;
      border: #aca9a97d solid 1px;
    }
  }

}

</style>