<script setup>
import { ref,useContext } from 'vue';

const pageStatus = ref(true);
const title = [
  ['一、企业基本情况', '1/8'],
  ['二、经济效益和经营情况', '2/8'],
  ['三、专业化发展水平', '3/8'],
  ['四、精细化发展水平', '3/8'],
  ['五、特色化发展情况', '4/8'],
  ['六、创新能力水平', '5/8'],
  ['七、产业链配套', '6/8'],
  ['八、主导产品所属领域', '7/8'],
  ['九、企业专精特新链品”指标情况一览表', '8/8'],
];
function show(){
  pageStatus.value = true;
}
useContext().expose({
  show
})
</script>

<template>
  <van-overlay :show="pageStatus" @click="pageStatus = false">
    <div class="wrapper" @click.stop>
      <div class="block">
        <div class="hang" v-for="(vo,i) in title" :key="i">
          <span class="title">{{ vo[0] }}</span>
          <div class="xian"></div>
          <span class="xu-hao">{{ vo[1] }}</span>
        </div>
        <div class="tal-text-center tal-mt-10">
          <van-button type="primary" @click="pageStatus = false">开始填写</van-button>
        </div>
      </div>
    </div>
  </van-overlay>
</template>

<style scoped lang="less">
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.block {
  min-width: 300px;
  max-width: 500%;
  width: 70%;
  //height: 120px;
  background-color: #fff;
  padding:40px 10px  20px;
  border-radius: 10px;
}

.hang {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  margin-bottom: 10px;
  .title{
    padding-right: 10px;
  }
  .xian{
    flex: 1;
    height: 1px;
    border-bottom: 1px #aaaa dotted;
  }
}
</style>