<template>
  <van-field
      v-bind="props.option.option"
      v-model="props.name"
      :placeholder="props.option.placeholder"
      @click="showPicker = true"
      :name="props.option.field"
      readonly
      :required="props.required"

  >
    <template #right-icon>
      <van-icon name="arrow-down" v-if="isJinYong"/>
    </template>
  </van-field>
  <van-popup v-model:show="showPicker" position="bottom" v-if="isJinYong">
    <van-area
        v-model="addressCode"
        @confirm="onConfirm"
        title="提示"
        :area-list="address"
        @cancel="showPicker = false"
        :columns-placeholder="['请选择', '请选择', '请选择']"
    />
  </van-popup>
</template>

<script setup>
import { computed, defineEmit, defineProps, ref, watch } from 'vue';
import XEUtils from 'xe-utils';
import { areaList } from '@/assets/js/vant-area-data';
import { Toast } from 'vant';

const addressCode = ref(''); //当前选中的code

const emits = defineEmit(['update:modelValue']);
const showPicker = ref(false); //是否显示选择
const selectValue = ref(null);
const props = defineProps({
  required: Boolean,
  option: Object,
  modelValue: [Number, String],
  name: String,
});
const address = XEUtils.get(props.option, 'areaList', areaList); //地址表
if (!XEUtils.isUndefined()) {
  const getValue = computed(() => {
    return 1;
  });
}

function onConfirm(result) {
  result = XEUtils.filter(result, n => !XEUtils.isEmpty(n));
  result = XEUtils.filter(result, n => !XEUtils.isEmpty(n.code));
  const name = [];
  const code = [];
  XEUtils.each(result, n => {
    name.push(n.name);
    code.push(n.code);
  });
  if (code.length !== 3) {
    Toast.fail('请选择到最后一级');
    return;
  }
  emits('update:modelValue', XEUtils.last(code));
  emits('update:name', name.join('/'));
  showPicker.value = false;
}

watch(() => props.name, () => {
      if (XEUtils.toString(props.modelValue) !== XEUtils.toString(addressCode.value)) {
        addressCode.value = XEUtils.toString(props.modelValue);
      }
    },
    {
      immediate: true,
    });

//false = 禁用
const isJinYong = computed(() => {
  return !props.option.option.disabled && !props.option.option.readonly;
});
</script>

<style scoped>

</style>