<script setup>
//底部菜单
import {defineProps, defineEmit} from 'vue'

const props = defineProps({
  modelValue: Number,
  sumNumber:Number,//总页数
})
const emit = defineEmit(["change"])

//上一页
function clickShangYiYe() {
  emit('change', 'up') //修改上级的v-model
}

//下一页
function clickXiaYiYe() {
  emit('change', 'next') //修改上级的v-model
}

//提交数据
function clickSubmit() {
  emit('change', 'submit') //修改上级的v-model
}

</script>

<template>
  <div class="tal-bg-white tal-h-24 tal-flex tal-items-center ">
    <div class="tal-w-screen tal-flex tal-items-center tal-justify-center tal-space-x-20">
      <div v-if="props.modelValue > 1">
            <span
                class="tal-p-3 tal-pl-10 tal-cursor-pointer tal-pr-10 tal-text-2xl tal-border-solid tal-border tal-rounded-full"
                @click="clickShangYiYe"
            >
              上一页
            </span>
      </div>
      <div v-if="props.modelValue < props.sumNumber">
          <span
              @click="clickXiaYiYe"
              class=" tal-p-3 tal-pl-10 tal-cursor-pointer tal-pr-10 tal-text-2xl tal-border-solid tal-border  tal-border-zhu-se tal-text-xl tal-rounded-full tal-bg-zhu-se tal-text-white">
              下一页
            </span>
      </div>
      <div v-if="props.modelValue === props.sumNumber">
            <span
                @click="clickSubmit"
                class="tal-p-3 tal-pl-10 tal-cursor-pointer tal-pr-10 tal-text-2xl tal-border-solid tal-border tal-border-zhu-se  tal-rounded-full tal-bg-zhu-se tal-text-white">
              提交
            </span>
      </div>
    </div>
  </div>
</template>

<style scoped lang="less">

</style>