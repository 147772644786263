<template>
  <van-field
      v-bind="props.option.option"
      :placeholder="props.option.placeholder"
      readonly
      :required="props.required"
      :name="props.option.field"
  >
    <template #input>
      <div>
        <van-checkbox-group direction="horizontal" v-model="selectValue" shape="square">
          <van-checkbox  v-for="(vo,index) in columns" :key="index" :name="vo.value" shape="square">
            {{ vo.text }}
          </van-checkbox>
        </van-checkbox-group>
      </div>
    </template>
  </van-field>
  <div class="duo-xuan">
    <van-action-sheet v-model:show="infoStatus.status" title="权限">
      <div class="quan-xian-content">
        <van-list>
          <van-cell v-for="(item,i) in infoStatus.content" :key="i" :title="item"/>
        </van-list>
      </div>
    </van-action-sheet>
  </div>
</template>

<script setup>
//多选框
import {computed, defineEmit, defineProps, onMounted, reactive, ref, watch} from "vue";
import XEUtils from "xe-utils";

const emits = defineEmit(['update:modelValue'])
const props = defineProps({
  required: Boolean,
  option: Object,
  modelValue: [Number, String]
})
const selectValue = ref([])
const infoStatus = reactive({
  content: [1, 2, 4],
  status: false
})


//同步上级
watch(() => selectValue.value, () => {
  emits('update:modelValue', selectValue.value.join(','))
})
//同步本组件
watch(() => props.modelValue, n => {

  if (props.modelValue !== selectValue.value.join(',')) {
    const val = XEUtils.toString(props.modelValue).split(',')
    selectValue.value = XEUtils.filter(val, n => !XEUtils.isEmpty(n))
  }
}, {
  immediate: true
})


//false = 禁用
const isJinYong = computed(() => {
  return !props.option.option.disabled && !props.option.option.readonly
})

/**
 * 检测是不是异步获取 true=异步
 * @returns {boolean}
 */



const columns = computed(() => {
  return XEUtils.map(XEUtils.get(props.option, 'dataList', []), n => {
    n.value = XEUtils.toString(n.value)
    return n
  })
})
</script>

<style scoped lang="less">
.quan-xian-content {
  max-height: 300px;
  overflow-y: auto;
}
.duo-xuan{
  //:deep(.van-checkbox--horizontal){
  //  margin-bottom: 5px !important;
  //
  //}
  ///deep/.van-checkbox--horizontal{
  //  margin-bottom: 5px !important;
  //}
  //::v-deep{
  //  .van-checkbox--horizontal{
  //    margin-bottom: 5px !important;
  //  }
  //}
}

</style>