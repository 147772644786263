<script setup>
import {defineProps, onMounted, ref, watch, defineEmit} from "vue";
import XEUtils from 'xe-utils'

const emit = defineEmit(['modelValue'])
const props = defineProps({
  required: Boolean,
  option: Object,
  modelValue: [Number, String],
  maxNumber: {
    type: Number,
    default: 1
  }
})
const input_val = ref([])

const get_input_val = () => {
  const val = []
  XEUtils.each(input_val.value, n => {
    if (!XEUtils.isEmpty(n.value)) {
      val.push(n.value)
    }
  })
  return val.join(',')
}

watch(() => props.modelValue, () => {
  if (get_input_val() === props.modelValue) return
  if (XEUtils.isString(props.modelValue)) {
    XEUtils.each(props.modelValue.split(','), n => {
      input_val.value.push({value: n})
    })

  }
}, {
  immediate: true,
})
watch(() => input_val.value, () => {
  emit('update:modelValue', get_input_val())
}, {
  deep: true,
})

onMounted(() => {
  if (input_val.value.length === 0) {
    input_val.value.push({value: ''})
  }
})

function addList() {
  input_val.value.push({value: ''})
}

function del(i) {
  XEUtils.remove(input_val.value, i)
}
</script>

<template>
  <div>
    <div class="xian-tiao" v-for="(vo,i) in input_val" :key="i">
      <van-field
          v-model="vo.value"
          v-bind="props.option.option"
          :required="props.required"
          :placeholder="props.option.placeholder"
          :name="props.option.field"
      >
        <template #right-icon>
          <div class="cao-zuo">
            <span v-if="input_val.length > 1">
              <van-icon @click="del(i)" color="#4a4096" name="cross"/>
            </span>
            <span v-if="input_val.length < props.maxNumber && i == 0">
              <van-icon @click="addList" color="#4a4096" name="plus"/>
           </span>
          </div>
        </template>
      </van-field>
    </div>
  </div>
</template>

<style scoped lang="less">
.cao-zuo {
  display: flex;

  span {
    margin-left: 5px;
  }
}

.xian-tiao {
  border-bottom: 1px #f5f5f5 solid;

  :last-child {
    border-bottom: 0;
  }
}
</style>